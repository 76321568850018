<!-- PWAPrompt.vue -->
<template>
  <v-alert
    color="secondary" dense
    :icon="icons.mdiStar"
  >
    <v-btn text block target="_blank"
           href="//groorsoa.net/4/6544838"
    >
      Visiter le site d'un sponsor

      <v-icon>{{ icons.mdiChevronRight }}</v-icon>
    </v-btn>
  </v-alert>

  <!--  <v-dialog v-if="shown" style="position: fixed; top: 0; width: 100%; height: 50px; background-color: blueviolet; z-index:1000000" >
      Add app to home screen?

      <button @click="installPWA">
        Install!
      </button>

      <button @click="dismissPrompt">
        No, thanks
      </button>
    </v-dialog>-->
</template>

<script>
import {
  mdiVuetify,
  mdiStar,
  mdiChevronRight,
} from '@mdi/js'

export default {
  data: () => ({
    shown: false,
    icons: {
      mdiVuetify,
      mdiStar,
      mdiChevronRight,
    }
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    dismissPrompt() {
      this.shown = false
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  }
}
</script>
