<template>
  <div>
    <v-row>
      <v-col
        lg="8"
        md="8"
        sm="12"
        xs="12"
      >
        <template v-if="post.postimages.length > 1">
          <v-carousel
            show-arrows
            continuous
          >
            <v-carousel-item
              v-for="(item,i) in post.postimages"
              :key="i"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-img
                contain
                :src="item.image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0 "
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </template>
        <v-img
          v-for="postimage in post.postimages"
          v-else-if="post.postimages.length"
          :src="postimage.image"
          contain
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0 "
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col
        lg="4"
        md="4"
        sm="12"
        xs="12"
      >
        <v-card>
          <v-card-actions>
            Publier une actualites
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-actions class=" pb-0">
            <v-btn
              :loading="loader.like"
              icon
              @click="toggleKola"
            >
              <v-icon>{{ post.liked ? icons.mdiHeart : icons.mdiHeartOutline }}</v-icon>
            </v-btn>
            <span v-if="post.nbkola>0">{{ post.nbkola }}</span>
            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>{{ icons.mdiComment }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text
            class="pb-0"
            v-html="post.contenthtml"
          ></v-card-text>

          <comment-component
            :quick_comment="true"
            :limit="limit"
            :post_id="post.id"
          ></comment-component>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiClose,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import draggable from 'vuedraggable'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import CommentComponent from '@/components/CommentComponent'

export default {
  components: {
    CommentComponent,
    draggable,
  },
  setup(props, { emit }) {
    const $vuetify = getVuetify()
    const user = Drequest.getUser()
    const items = ref([])
    const filestoupload = ref([])
    const files = ref([])
    const images = ref([])
    const nbimageuploaded = ref(0)
    const nbimages = ref(0)
    const post = ref({})
    const loaders = ref({
      post: false,
    })
    const snackbar = ref({})
    const postcontent = ref('')

    const closecomponent = () => {

    }
    const addimage = () => {

    }

    const addimages = files => {
      console.log(files)

      /* if(!this.post.id){
        alert("Oops une erreur est survenue lors du chargement de vos images. Selectionnez de nouveau svp. si le problème persiste, actualiser la page, votre publication sera conservée.");
        return 0;
      } */
      // files.value = ev.target.files
      if (files[0]) {
        for (let i = 0; i < files.length; i++) {
          files[i].toupload = true
          filestoupload.value.push({
            file: files[i],
            oburl: window.URL.createObjectURL(files[i]),
          })
        }
      }
      console.log(filestoupload.value)
      nbimages.value = filestoupload.value.length
    }

    const removeimage = index => {
      filestoupload.value.splice(index, 1)
      nbimages.value--

      /* Drequest.api('postimage.delete?id=' + file.id).get((response) => {
        // model._apiget('product-image.delete?id=' + file.id, (response) => {
        console.log(response);
        this.filestoupload.splice(index, 1);
      }); */
    }

    const urlify = text => {
      const urlRegex = /(https?:\/\/[^\s]+)/g

      return text.replace(urlRegex, url => `<a href="${url}">${url}</a>`)

      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

    const publish = () => {
      if (!nbimages.value) {
        window.alert('Vous devez inserer au moins une image ou une video.')

        return 0
      }
      const fd = new FormData()
      fd.append('userid', user.id)
      fd.append('content', postcontent.value)
      fd.append('nbimages', nbimages.value)

      let img = {}
      console.log(filestoupload.value)
      for (let i = 0; i < nbimages.value; i++) {
        img = filestoupload.value[i]
        fd.append(`postimage_${i}`, img.file)
      }
      loaders.value.post = true
      Drequest.api('post.persist')
        .data(fd)
        .post(response => {
          console.log(response)
          loaders.value.post = false
          filestoupload.value = []
          postcontent.value = ''
          images.value = []
          nbimages.value = 0
          emit('posted', response.post)
        })
    }

    return {
      addimages,
      publish,
      removeimage,

      items,
      post,
      loaders,
      postcontent,
      images,
      nbimages,
      filestoupload,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.todo{
  border-top: 1px solid var(--v-secondary-base);
}
.list-move {
  transition: .3s;
}
</style>
