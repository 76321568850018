<template>
  <div class="">
    <div class="">
      <v-card class="">
        <!-- logo -->
        <v-card-title class="d-flex py-7">
          <h2 class="text-2xl font-weight-semibold">
            Souscription
          </h2>
          <v-spacer>
          </v-spacer>
          <v-btn
            color="primary"
            @click="dialog.newrefill = true"
          >
            Faire une souscription
          </v-btn>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <v-list>
            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Historique des souscriptions
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>{{ icons.mdiArrowRight }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list
            nav
            dense
          >
            <v-list-item-group
              color="primary"
            >
              <v-list-item
                v-for="item in hsubscription.listEntity"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Du {{ item.date_from }} au {{ item.date_to }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Duree : {{ item.duration }} Jours</v-list-item-subtitle>
                  <v-list-item-action-text>Credit bonus: {{ item.credit }}</v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog.newrefill"
      max-width="500"
    >
      <v-card elevation="0">
        <v-card-title>
          Souscrire a un abonnement.
          <v-spacer></v-spacer>
          <v-btn
            icon
            text
            @click="dialog.newrefill = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list
            nav
            dense
          >
            <v-list-item-group color="primary">
              <v-list-item
                v-for="item in subscriptiontypes"
                :key="item.id"
                @click="subscription = item; dialog.confirm = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }} a {{ item.price }} Cdts
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-btn @click="dialog.newrefill = false">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.confirm"
      persistent
      max-width="300"
    >
      <v-card color="">
        <v-card-text class="text-center pt-6">
          Confirmer la souscription a l'abonnement de <b>{{ subscription.name }}</b> qui vous
          coutera <b>{{ subscription.price }}</b> Credits<br>
          <p>{{ subscription.description }}</p>
          <v-alert
            v-if="dialog.fail"
            color="warning"
            class="text-center pt-6"
          >
            {{ dialog.detail }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-btn
            block
            color="primary"
            :loading="dialog.loading"
            @click="confirmSubscribe"
          >
            Confirme mon abonnement
          </v-btn>
        </v-card-actions>

        <v-card-actions class="text-center">
          <v-btn 
            block
            text
            small
            @click="dialog.confirm = false"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="dialog.snackbar"
      color="success"
    >
      {{ dialog.detail }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="dialog.snackbar = false"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { useRouter } from '@/utils'

export default {
  components: { },
  setup() {
    const user = Drequest.getUser()
    const subscription = ref({ })
    const hsubscription = ref({})
    const subscriptiontypes = ref([])
    const dialog = ref({
      newrefill: false,
      snack: false,
      confirm: false,
      loading: false,
      detail : ""
    })

    const { router } = useRouter()
    const route = router.currentRoute
    if (route.params.option) dialog.value.newrefill = true

    const init = () => {
      Drequest.getSubscription(user)
        .get(response => {
          hsubscription.value = response
        })
      Drequest.api('lazyloading.product?dfilters=on&category.slug:eq=subscription&dlang=fr')
        .get(response => {
          console.log(response)
          subscriptiontypes.value = response.listEntity
        })
    }
    init()

    const refilled = () => {
      dialog.value.newrefill = false
      dialog.value.snack = true
      init()
    }

    const confirmSubscribe = () => {
      dialog.value.loading = true
      Drequest.orderSubscribe(subscription.value)
        .raw(response => {
          console.log(response)
          dialog.value.loading = false
          dialog.value.detail = response.detail
          if (response.success) {
            dialog.value.snackbar = true

            dialog.value.loading = false
            dialog.value.confirm = false
            dialog.value.newrefill = false
            Drequest.updateUserSession(user)
            init()
          } else {
            dialog.value.fail = true
          }
        })
        .fail(e => {
          dialog.value.loading = false
          alert('Oops Il y a eu un probleme de connexion essayer de nouveau SVP !')
          console.log(e)
        })
    }

    return {
      init,
      refilled,
      confirmSubscribe,

      subscription,
      hsubscription,
      subscriptiontypes,
      user,
      dialog,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
