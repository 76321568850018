<template>
  <div>

    <v-card
      elevation="0"
      class=""
    >
      <v-card-title class="pa-2">
        Membres ({{nbmember}})
        <v-spacer></v-spacer>

        <v-btn icon
               to="/community"
               small
               color="primary"
        >
          <v-icon>{{icons.mdiArrowRight}}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-actions
        v-if="loader.reloadchapter"
        class="text-center"
      >
        <v-btn
          class="ma-auto"
          color="primary"
          @click="init()"
        >
          Recharger
        </v-btn>
      </v-card-actions>
      <template v-else>
        <v-sheet
          v-if="loader.news"
          class="mx-auto"
        >
          <v-slide-group
            multiple
            :show-arrows="showarrow"
          >
            <v-slide-item
              v-for="n in 6"
              :key="'lnew'+n"
              v-slot="{ }"
            >
              <v-card
                :color="`grey darken-2 `"
                class="pa-3"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  :height="slider_height.news"
                  :width="columns"
                  type="avatar"
                ></v-skeleton-loader>
              </v-card>
            </v-slide-item>

          </v-slide-group>
        </v-sheet>
        <v-sheet
          v-else
          class="mx-auto "
        >
          <v-slide-group
            multiple
            :show-arrows="showarrow"
          >
            <v-slide-item
              v-for="(item, index) in members"
              :key="'new-'+index"  class="pb-3 ml-2"
              v-slot="{ }"
            >

              <profile-item
                :user="user" :profile="item"></profile-item>

            </v-slide-item>
            <v-slide-item
              :key="'new-00'"  class="pb-3"
              v-slot="{ }"
            >

              <v-card
                :color="`grey darken-2 `"
                class="pa-3"
              >
                <v-btn
                       to="/community"
                       stacked
                >
                  <template v-slot:prepend>
                    <v-icon>{{icons.mdiArrowRight}}</v-icon>
                </template>
                  Voir Plus
                </v-btn>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </template>
    </v-card>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline, mdiCartOutline, mdiClose, mdiArrowRight
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import ProfileItem from "@/components/ProfileItem.vue";

export default {
  components: {ProfileItem},
  props:{
    user:{
        type: Object,
        default: ()=>{},
      },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const nbmember = ref(0)
    const members = ref([])
    const loader = ref({
      news: true,
      oneshotnews: true,
      products: true,
      reloadprod: false,
      reloadchapter: false,
      oneshotreloadchapter: false,
    })
    const mcs = ref([])
    const advertising = ref({})
    const socialLink = [
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 120
      }
      if ($vuetify.breakpoint.xs) {
        return 108
      }

      return 210
    })

    const showarrow = computed(() => {
      if ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) {
        return false
      }

      return true
    })

    // const user = Drequest.getUser()

    const init = () => {
      loader.value.reloadchapter = false
      loader.value.reloadprod = false

      let url;
      url = 'lazyloading.user?dfilters=on&next=1&per_page=9&drand=1&username:neq=';

      Drequest.api(url)// &user_id=269&subscription_id=1
        .get(response => {
          console.log(response)
          loader.value.news = false
          members.value = response.listEntity
          nbmember.value = response.nb_element
        })
        .fail(e => {
          loader.value.news = false
          loader.value.reloadchapter = true
          console.log(e)
        })

    }
    init()
    const slider_height = computed(() => {
      if ($vuetify.breakpoint.xl) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.lg) {
        return {
          news: 270,
          product: 270,
        }
      }

      if ($vuetify.breakpoint.md) {
        return {
          news: 210,
          product: 210,
        }
      }
      if ($vuetify.breakpoint.sm) {
        return {
          news: 180,
          product: 180,
        }
      }
      if ($vuetify.breakpoint.xs) {
        return {
          news: 150,
          product: 150,
        }
      }

      return {
        news: 270,
        product: 270,
      }
    })

    return {
      init,

      advertising,
      loader,
      socialLink,
      nbmember,
      members,
      columns,
      mcs,
      showarrow,
      slider_height,

      icons: {
        mdiCartOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiArrowRight,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
