<template>
<span>
     <v-btn small color="warning" @click="onShare" class="ml-3" rounded>
          <v-icon>{{ icons.mdiShareVariantOutline }}</v-icon>
          <span class="ml-3 hidden-xs-only hidden-sm-only">Partager</span>
        </v-btn>

    <v-dialog style="z-index: 1002" max-width="600" v-model="dialog.sharebox">
      <share-component @close="()=>dialog.sharebox = false"
                       :linkshare="url"
      ></share-component>
    </v-dialog>
</span>
</template>

<script>

import {computed, ref} from '@vue/composition-api'
import {
  mdiComment,
  mdiDotsVertical, mdiEyeOffOutline,
  mdiEyeOutline,
  mdiHeart,
  mdiShareVariantOutline,
  mdiShareVariant
} from '@mdi/js'
import ShareComponent from "@/components/ShareComponent.vue";

export default {
  components: {ShareComponent},
  props: {
    'url': String,
    'message': String,
    'title': String,
  },
  setup(props, {emit}) {

    const dialog = ref({
      loading: true,
      sharebox: false,
    })
    const onShare = () => {
      if (navigator.share) {
        navigator.share({
          title: props.title,
          text: props.message,
          url: props.url,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Share not supported on this browser, do it the old way.');
        dialog.value.sharebox = true
      }
    }
    return {
      dialog,
      onShare,
      icons: {
        mdiDotsVertical,
        mdiComment,
        mdiShareVariant,
        mdiHeart,
        mdiShareVariantOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  }
}
</script>

<style scoped>

</style>
