<template>
  <v-card elevation="0">
    <!-- logo -->
    <v-card-title class="d-flex align-center justify-center py-7">
      <span
        class="d-flex align-center"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text-2xl font-weight-semibold">
          3ag reader
        </h2>
      </span>
    </v-card-title>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          @click="e1 = 1"
        >
          Me connecter
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="2"
          @click="e1 = 2"
        >
          Creer mon compte
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <!-- login form -->
          <v-form class="mt-3">
            <v-text-field
              v-model="email"
              outlined
              label="Email / Numero de telephone"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forgot link -->
              <a
                href="javascript:void(0)"
                class="mt-1"
                @click="forget_pwd = true"
              >
                Forgot Password?
              </a>
            </div>

            <v-alert
              v-model="server_response.alert"
              :color="server_response.color"
            >
              {{ server_response.detail }}
            </v-alert>
            <v-btn
              block
              type="button"
              :loading="loader.login"
              color="primary"
              class="mt-6"
              @click="login"
            >
              Connexion
            </v-btn>
          </v-form>
          <!-- create new account  -->
          <v-card-text class="d-flex align-center justify-center flex-wrap mt-3">
            <span class="me-2">
              Nouveau sur l'application?
            </span>
            <v-btn class="mt-3" block outlined color="primary" @click="e1 = 2">
              Creer mon compte
            </v-btn>
            <v-btn class="mt-3" block text @click="$emit('closedialog')">
              Annuler
            </v-btn>
          </v-card-text>
        </v-stepper-content>

        <v-stepper-content step="2">
          <!-- login form -->
          <register-component @back="()=>e1 = 1"></register-component>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog
      v-model="forget_pwd"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          Mot de passe oublie
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            @click="forget_pwd = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>

        <forget-password-component @reseted="reseted"></forget-password-component>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiClose,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import RegisterComponent from '@/components/RegisterComponent'
import ForgetPasswordComponent from '@/components/ForgetPasswordComponent'

export default {
  components: {
    ForgetPasswordComponent,
    RegisterComponent,
  },
  props: {
    newaccount: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const loader = ref({
      login: false,
      forget_pwd: false,
    })
    const forget_pwd = ref(false)
    const e1 = ref(1)

    if (props.newaccount) {
      e1.value = 2
    }

    const countries = ref([])
    const country = ref({})
    const user = ref({})
    const server_response = ref({
      alert: false,
      color: 'info',
    })

    const init = () => {
      Drequest.api('lazyloading.country?dfilters=on&next=1&per_page=1000')
        .get(response => {
          countries.value = response.listEntity
        })
    }
    init()
    const login = () => {
      loader.value.login = true
      Drequest.api('user.authentification')
        .toFormdata({
          login: email.value,
          password: password.value,
        })
        .post(response => {
          console.log(response)
          loader.value.login = false
          if (!response.success) {
            server_response.value.alert = true
            server_response.value.color = 'error'
            server_response.value.detail = 'Login ou mot de passe incorrecte' // response.detail

            return
          }
          server_response.value.alert = true
          server_response.value.color = 'success'
          server_response.value.detail = response.detail // response.detail
          Drequest.setUser(response.user)
          emit('logged', response.user, response.detail)
        })
        .fail(e => {
          server_response.value.alert = true
          server_response.value.color = 'error'
          server_response.value.detail = 'Oops. Nous n\'arrivons pas a nous connecter. Veuillez réessayer svp.' // response.detail
        })
    }

    const reseted = user => {
      Drequest.setUser(user)
      window.location.reload()
    }

    return {
      login,
      reseted,

      isPasswordVisible,
      email,
      forget_pwd,
      password,
      loader,
      server_response,
      e1,
      countries,
      country,
      user,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
