<template>

  <v-card :loading="loaders.loading" flat>

    <v-row dense>
      <v-col v-if="user_id == idprofile && chapterll.listEntity.length > 0"
             :key="'lnew'"
             class="d-flex child-flex v-alert--border"
             cols="6"
      >
        <v-card :to="'/editchapter'"
                elevation="0"
                max-width="600"
                rounded="lg"
                width="100%"
                class="pa-4 text-center mx-auto"
        >
          <v-icon
            class="mb-5"
            color="info"
            size="112"
          >{{ icons.mdiPlus }}
          </v-icon>

          <h2 class="text-h5 mb-6">Nouveau One-Shot</h2>

        </v-card>
      </v-col>
      <v-col
        v-for="(item, index) in chapterll.listEntity"
        :key="index"
        class="d-flex child-flex"
        cols="6" lg="4" md="4"
      >
        <v-card>
          <v-card-title class="justify-space-between pa-0 pa-lg-3 pa-md-3">
            <v-btn small :to="'/scan/one-shot/'+item.id">
              Lire
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn small :to="`/one-shot/${item.slug}/`+item.id">
              Detail
            </v-btn>
          </v-card-title>
          <v-card-subtitle>{{ item.title }}</v-card-subtitle>
          <v-img
            max-height="400"
            class="white--text align-end"
            aspect-ratio="1"
            :src="item.cover_src"
            :lazy-src="require('@/assets/images/holder.jpg')"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 "
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <template v-if="user_id == idprofile" >
            <v-card-actions class="text-center pa-0 pa-lg-3 pa-md-3">
              <v-btn-toggle class="ma-auto" dense
                            variant="outlined"
                            divided
              >
                <v-btn small   :to="'/chapter/form/'+item.id">
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  <span class="hidden-xs-only ml-3">Modifier</span>
                </v-btn>
                <v-btn small   @click="deleteChap(item, index)">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                  <span class="hidden-xs-only ml-3">Supprimer</span>
                </v-btn>
              </v-btn-toggle>

            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <v-sheet
      v-if="loaders.loading"
      class="mx-auto"
    >
      <v-row dense>
        <v-col
          v-for="n in 9"
          :key="'lnew'+n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-card
            :color="`grey darken-2 `"
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              :height="120"
              :width="120"
              type="card"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>

    <!--
        <v-card v-intersect="infiniteScrolling"></v-card>-->

    <v-sheet v-if="!loaders.loading && chapterll.listEntity.length === 0"
             elevation="0"
             max-width="600"
             rounded="lg"
             width="100%"
             class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="warning"
        size="112"
      >{{ icons.mdiExclamation }}
      </v-icon>

      <h2 class="text-h5 mb-6">Aucun element trouve</h2>

      <template v-if="user_id == idprofile">
        <p class="mb-4 text-medium-emphasis text-body-2">
          Vous pouvez creer vos propre series et one-shot
        </p>

        <v-divider class="mb-4"></v-divider>

        <div class="text-center">
          <v-btn
            class="text-none"
            color="success"
            rounded
            variant="flat" :to="'/editchapter'"
          >
            Ajouter un One-shot
          </v-btn>
        </div>
      </template>

    </v-sheet>

    <template v-if="user_id == idprofile">

      <v-dialog fullscreen v-model="dialog.newchapter">

      </v-dialog>

    </template>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
  mdiExclamation,
  mdiPlus,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import ProfileItem from "@/components/ProfileItem.vue";
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
import ChapterImageFormController from "@/components/ChapterImageFormController.vue";
import ImageUploadController from "@/components/ImageUploadController.vue";

export default {
  components: {
    ImageUploadController,
    ChapterImageFormController,
    ChapterFormComponent,
    ProfileItem,
    FollowToggleComponent,
  },
  props: {
    chapteroption: Object,
    idprofile: Number|String,
    user_id: Number|String,
    user: Object,
  },
  setup(props) {
    const dialog = ref({
      newchapter: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })

    const chapterll = ref({listEntity: []})
    const loadchapter = next => {

      loaders.value.loading = true
      loaders.value.retry = false


      Drequest.api('lazyloading.chapter?dfilters=on&dsort=id desc')
        .param({
          'user.id:eq': props.idprofile,
          'comicbook.id:isNull': 1,
          per_page: 12,
          next,
          user_id: props.user_id,
          ...props.chapteroption,
        })
        .get(response => {
          console.log(response)
          loaders.value.loading = false
          if (next >= 2) {

            chapterll.value.listEntity = [...chapterll.value.listEntity, ...response.listEntity]
            chapterll.value.next = response.next

          } else {
            loaders.value.loading = false
            chapterll.value = response

          }
        })
        .fail(e => {
          loaders.value.loading = false
          loaders.value.retry = true
          console.log(e)
        })

    }
    loadchapter(1)

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loaders.value.loading)

      if (loaders.value.loading) return

      console.log(chapterll.value.next >= 2, chapterll.value)
      // chapterll.value.next >= 2 &&
      if (chapterll.value.remain) loadchapter(chapterll.value.next)

    }

    const deleteChap = (chapter, index) => {
      console.log(loaders.value.loading)

      if (!confirm('Confirmer la suppression de ce One shot?.'))
        return 0;

      Drequest.api('chapter.delete?id=' + chapter.id)
        .get(response => {
          console.log(response)
          chapterll.value.listEntity.splice(index, 1)
        })

    }

    return {
      infiniteScrolling,
      deleteChap,

      chapterll,
      loaders,
      dialog,

      icons: {
        mdiClose,
        mdiExclamation,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 120 !important;
}

/*.v-dialog__content {
  z-index: 4001 !important;
}*/
</style>
