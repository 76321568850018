<!-- PWAPrompt.vue -->
<template>
  <v-overlay
    :opacity="1"
    :value="overlay"
    class="text-center"
    style="z-index: 1000000000"
  >
    <v-progress-circular indeterminate size="120">
      <img
        alt=""
        :src="require('@/assets/images/logos/logo.png')"
      />
    </v-progress-circular>
    <br>
    Loading ...
    <br>
    Le chargement peut prendre quelques temps pour les premieres visites de l'application.
  </v-overlay>
</template>

<script>
import {
  mdiVuetify,
} from '@mdi/js'

export default {
  data: () => ({
    overlay: true,
    uuid: null,
    timer: 3000,
  }),

  beforeMount() {
    this.uuid = localStorage.getItem('uuid_session')
    if (!this.uuid)
      this.timer = 6000
  },
  mounted() {
    setTimeout(() => {
      this.overlay = false
    }, this.timer)
  },
}
</script>
