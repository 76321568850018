<!-- PWAPrompt.vue -->
<template>
  <v-alert
    style="position: fixed; top: 0; width: 100%; z-index:1000000"
    v-model="shown"
    color="warning"
    prominent
    :icon="icons.mdiVuetify"
  >
    <v-row align="center">
      <v-col class="">
        Installer <b>3ag Reader</b> pour plus de convivialite
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="primary"
          class="mr-3"
          @click="installPWA"
        >
          Installer l'application
        </v-btn>
        <v-btn
          text
          @click="dismissPrompt"
        >
          Non merci
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>

  <!--  <v-dialog v-if="shown" style="position: fixed; top: 0; width: 100%; height: 50px; background-color: blueviolet; z-index:1000000" >
      Add app to home screen?

      <button @click="installPWA">
        Install!
      </button>

      <button @click="dismissPrompt">
        No, thanks
      </button>
    </v-dialog>-->
</template>

<script>
import {
  mdiVuetify,
} from '@mdi/js'

export default {
  data: () => ({
    shown: false,
    icons: {
      mdiVuetify,
    }
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    dismissPrompt() {
      this.shown = false
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  }
}
</script>
