<template>
  <v-card
    elevation="0"
    class="auth-card"
  >
    <v-tabs class=" font-secondary"
            v-model="tab" center-active
            align-tabs="center"
            grow
    >
      <v-tab
        key="papers"
      >
        Selectionner un avatar :D
      </v-tab>
      <v-tab
        key="series"
      >
        Charger son propre avatar
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key="papers"
        style="min-height: 500px"
      >
        <v-card-title>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-avatar v-if="user.profile"
            :key="user.profile"
            size="72"
            contain
            :class=" !user.avatar ? 'ma-2 av-active' : ' ma-2 av'"
            @click="setProfile(null)"
          >
            <v-img
              alt="Avatar"
              contain
              :src="user.profile"
            ></v-img>
          </v-avatar>
          <template v-for="(av, $index) in $store.state.avatars">
            <v-avatar
              :key="av.path"
              size="72"
              contain
              :title="av.name"
              :class="av.path === user.avatar ? 'ma-2 av-active' : ' ma-2 av'"
              @click="setProfile( av.path )"
            >
              <v-img
                alt="Avatar"
                contain
                :src="require(`@/assets/images/avatars/${av.path}`)"
              ></v-img>
            </v-avatar>
          </template>
        </v-card-text>


      </v-tab-item>
      <v-tab-item
        :key="'series'"
        style="min-height: 500px"
      >

        <image-upload-controller
          :image="user.profile"
          :url="'upload.user?id='+user.id "
          :keyform="'user_form[profile]'"
          :label="'Charger un avatar'"
          @uploaded="profileUploaded"
        ></image-upload-controller>

      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import ImageUploadController from "@/components/ImageUploadController.vue";
import store from "@/store";

export default {
  components: {
    ImageUploadController,
  },
  data(){
    return {
    }
  },
  //props: ['user'],
  setup(props, {emit}) {


    const user = ref(store.state.user)
    const loader = ref(false)
    const e1 = ref(1)
    const tab = ref(0)
    const server_response = ref({
      error: {},
      error_message: '',
      snack: false,
      alert: false,
      loadactivation: false,
      loadregistration: false,
    })


    const profileUploaded = (response) => {
      console.log(response)
      store.commit('updateUser', {avatar:null, profile: response.user.profile})
      user.value.avatar = null
      user.value.profile = response.user.profile
      Drequest.api('update.user?id='+user.value.id)
        .data({
          user:{avatar:null, profile: response.user.profile}
        })
        .raw((response)=>{
          console.log(response)
        })
    }
    const setProfile = ( value ) => {
      console.log(value )
      store.commit('updateUser', {avatar: value})
      user.value.avatar = value
      Drequest.api('update.user?id='+user.value.id)
        .data({
          user:{avatar:value}
        })
        .raw((response)=>{
          console.log(response)
        })
    }

    return {
      profileUploaded,
      setProfile,

      loader,
      server_response,
      e1,
      tab,
      user,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
