<template>
  <div class="">
    <v-tabs
      v-model="tab"
      color="primary"
      grow
    >
      <v-tab @click="init" key="follower">
        Followers
      </v-tab>
      <v-tab @click="initFollowed" key="followed">
        Suivi(e)s
      </v-tab>
      <v-tab key="subs">
        Abonnements
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key="follower"
        class="pa-0 pb-16"
      >
        <v-card
          elevation="0"
          :loading="loader.catalog"
        >
          <v-card-text class="pa-1 pt-3">
            <v-text-field
              dense
              label="Recherche ..."
              outlined
            ></v-text-field>
          </v-card-text>
        </v-card>
        <div
          v-if="loader.reload"
          class="pa-lg-6 pa-sm-3 text-center"
        >
          <v-btn
            class="ma-auto"
            color="primary"
            @click="init()"
          >
            Recharger
          </v-btn>
        </div>
        <div
          v-else
          class="pa-lg-6 pa-sm-3"
        >
          <v-row v-if="loader.catalog">
            <v-col
              v-for="n in 9"
              :key="'new-'+n"
              class="d-flex child-flex"
              cols="4"
            >
              <v-sheet
                :color="`grey darken-2 `"
              >
                <v-skeleton-loader
                  class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
                  style="margin-left:3px; margin-right:3px; vertical-align: top"
                  :width="columns"
                  max-width="210"
                  min-width="108"
                  type="card"
                ></v-skeleton-loader>
              </v-sheet>
            </v-col>
          </v-row>

          <v-row
            v-else>
            <v-col
              v-for="(item, index) in followersll.listEntity"
              :key="index"
              class="d-flex child-flex"
              cols="4"
            >
              <profile-item
                :user="user" :profile="item.follower"></profile-item>
            </v-col>
          </v-row>

        </div>
      </v-tab-item>
      <v-tab-item
        key="followed"
      >

        <v-row v-if="loader.followed">
          <v-col
            v-for="n in 9"
            :key="'new-'+n"
            class="d-flex child-flex"
            cols="4"
          >
            <v-sheet
              :color="`grey darken-2 `"
            >
              <v-skeleton-loader
                class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
                style="margin-left:3px; margin-right:3px; vertical-align: top"
                :width="columns"
                max-width="210"
                min-width="108"
                type="card"
              ></v-skeleton-loader>
            </v-sheet>
          </v-col>
        </v-row>
        <div
          v-else
          class="pa-lg-6 pa-sm-3"
        >
        <v-row >
          <v-col
            v-for="(item, index) in followedsll.listEntity"
            :key="index"
            class="d-flex child-flex"
            cols="4"
          >
            <profile-item
              :user="user" :profile="item.follower"></profile-item>
          </v-col>
        </v-row>
        </div>

      </v-tab-item>
      <v-tab-item
        key="subs"
      >
        list des mcs
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
import {useRouter} from "@/utils";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ProfileItem from "@/components/ProfileItem.vue";
// import { useMeta } from 'vue-meta'

export default {
  components: {ProfileItem, FollowToggleComponent, Banner},
  setup() {
    //useMeta({ title: 'Some Page' })
    const $vuetify = getVuetify()
    const loader = ref({
      catalog: true,
      reload: false,
      followed: false,
    })
    const items = ref([])
    const followersll = ref({})
    const followedsll = ref({})
    const drawer = ref(false)
    const tab = ref('follower')
    const {router} = useRouter()

    const route = router.currentRoute
    const idprofile = parseInt(route.params.idprofile)
    const user_id = ref((localStorage.getItem('user_id')))
    const user = ref(Drequest.getUser())

    const columns = computed(() => {
      if ($vuetify.breakpoint.md) {
        return '20%'
      }
      if ($vuetify.breakpoint.sm) {
        return '31%'
      }
      if ($vuetify.breakpoint.xs) {
        return '31%'
      }

      return '13%'
    })
    const init = () => {
      loader.value.reload = false
      loader.value.catalog = true
      Drequest.api('user.profile-followers?dfilters=on&per_page=9&next=1&profile_id=' + idprofile + '&tab=follower')
        .get(response => {
          console.log(response)
          followersll.value = response
          loader.value.catalog = false

        })
        .fail(e => {
          loader.value.reload = true
        })
    }
    const initFollowed = () => {
      loader.value.reload = false
      loader.value.followed = true
      Drequest.api('user.profile-followers?dfilters=on&per_page=9&next=1&profile_id=' + idprofile + '&tab=followed' )
        .get(response => {
          console.log(response)
          followedsll.value = response
          loader.value.followed = false
        })
        .fail(e => {
          loader.value.reload = true
        })
    }
    init()
    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    return {
      detail,
      init,
      initFollowed,

      user,
      loader,
      tab,
      items,
      followersll,
      followedsll,
      columns,
      drawer,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
