<template>
  <div>
    <v-card>
      <v-carousel
        :height="slider_height"
        continuous hide-delimiters
        hide-delimiter-background
        cycle
        :show-arrows="false"
      >
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
        >
          <v-img v-if="item.redirect"
                 :to="item.redirect"
                 :height="slider_height"
                 :src="item.image"
                 cover
          ></v-img>
          <v-img v-else
                 :height="slider_height"
                 :src="item.image"
                 cover
          ></v-img>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'

export default {
  setup() {
    const $vuetify = getVuetify()
    const items = ref([])
    const slider_height = computed(() => {
      if ($vuetify.breakpoint.xl) {
        return 600
      }

      if ($vuetify.breakpoint.lg) {
        return 500
      }

      if ($vuetify.breakpoint.md) {
        return 300
      }
      if ($vuetify.breakpoint.sm) {
        return 200
      }
      if ($vuetify.breakpoint.xs) {
        return 200
      }

      return 500
    })

    const init = () => {
      Drequest.api('lazyloading.slide')
        .get(response => {
          console.log(response)
          items.value = response.listEntity
        })
    }
    init()

    return {
      items,
      slider_height,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
