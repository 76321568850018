<template>
  <v-card
    elevation="0"
    class="auth-card"
  >
    <!-- login form -->
<v-card-title>
  Creer Mon compte </v-card-title>
    <v-form
      class="mt-3"
    >
      <v-text-field
        v-model="user.username"
        outlined
        dense
        label="Nom d'utilisateur"
        hide-details
        required
        :rules="[validators.required]"
        class="mb-3 pa-0"
      ></v-text-field>

      <v-row>
        <v-col cols="12" lg="5">
          <v-select
            class=" pa-0"
            v-model="country"
            outlined
            dense
            :items="countries"
            label="Pays"
            item-value="id"
            item-text="phonecode"
            required
            return-object
          >
            <template v-slot:selection="data">
              (+{{ data.item.phonecode }}) {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                (+{{ data.item.phonecode }}) {{ data.item.name }}
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" lg="7">
          <v-text-field
            v-model="user.phonenumber"
            outlined
            dense
            :error-messages="server_response.error.phonenumber"
            :hint="server_response.error.phonenumber"
            label="Numero de telephone"
            hide-details
            class="mb-3 pa-0"
          >
            <template v-slot:prepend-inner>
              + {{ country.phonecode }}
            </template>
          </v-text-field>
          <p class="error--text">
            {{ server_response.error.phonenumber }}
          </p>
        </v-col>
      </v-row>

      <v-text-field
        v-model="user.email"
        outlined
        dense
        label="Email"
        :rules="[validators.emailValidator]"
        :error-messages="server_response.error.email"
        :hint="server_response.error.email"
        placeholder="john@example.com"
        hide-details
        class="mb-3 pa-0"
      ></v-text-field>
      <p class="error--text">
        {{ server_response.error.email }}
      </p>

      <v-text-field
        v-model="user.password"
        outlined
        class=" pa-0"
        dense
        :rules="[validators.required]"
        :error-messages="server_response.error.password"
        :type="isPasswordVisible ? 'text' : 'password'"
        label="Password"
        placeholder="············"
        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
        hide-details
        @click:append="isPasswordVisible = !isPasswordVisible"
      ></v-text-field>
      <v-btn
        :loading="server_response.loadregistration"
        block
        color="primary"
        class="mt-6 pa-0"
        @click="register"
      >
        Creer mon compte
      </v-btn>
      <v-btn
        block
        color="primary"
        class="mt-6 pa-0"
        @click="registration_completed = true"
      >
        Creer mon compte dddd
      </v-btn>
    </v-form>

    <v-dialog
      v-model="confirm_account"
      persistent
      max-width="420"
    >
      <v-card>
        <v-card-title>
          Validation du compte
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            @click="confirm_account = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>Un code d'activation vous a ete envoye par SMS et/ou E-mail.</p>
          <v-otp-input
            v-model="user.activationcode"
            outlined
            dense
            :label="('Entrer le code d\'activation')"
            length="5"
            hide-details
            class="mb-3"
          ></v-otp-input>
          <v-text-field
            v-model="user.activationcode"
            :label="('Entrer le code d\'activation')"
            outlined
            dense
            counter="5"
            maxlenght="5"
            class="mb-3"
            :error-messages="server_response.error_message"
          ></v-text-field>
          <p>
            Vous n'avez pas recu de code?
            <v-btn
              small
              text
              @click="resendcode"
            >
              Renvoyer le code
            </v-btn>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            :loading="server_response.loadactivation"
            type="submit"
            small
            class="mt-4"
            color="primary"
            @click="validateAccount"
          >
            <v-icon
              style="color: white"
              size="20"
            >
              {{ icons.mdiAccountCircle }}
            </v-icon>
            Activer mon compte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="registration_completed"
      persistent

      max-width="420"
      overlay-color="secondary"
    >

      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            step="1"
            @click="e1 = 1"
          >
            Bio et Avatar
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="2"
            @click="e1 = 2"
          >
            Terminer
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items class="pa-0 pa-md-3 pa-lg-6">
          <v-stepper-content  class="pa-0 pa-md-3 pa-lg-6" step="1">

            <v-card-text>Choisissez votre avatar ou alors charger une autre image </v-card-text>
            <profile-avatar-component
              :user="user"
            ></profile-avatar-component>
            <v-card-actions >
              <v-spacer></v-spacer>
              <v-btn  outlined @click="e1 = 2">
                Continuer
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content  class="pa-0 pa-md-3 pa-lg-6" step="2">

            <v-card class="text-center">
              <v-img
                :src="require('@/assets/images/pages/welcome.png')"
                max-height="210px"
                max-width="300px"
                alt="welcome"
                contain
                class="me-3 "
              ></v-img>

              <v-card-text class="text-center">
                <p>{{ server_response.detail }}</p><br><br>
                <h3>Truc et astuces</h3>
                <p>{{ server_response.astuce }}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn small outlined @click="e1 = 1">
                  Retour
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="mr-3"
                  text small
                  @click="registration_completed = false; window.location.reload()"
                >
                  Fermer
                </v-btn>
                <v-btn small
                  color="primary"
                  to="/subscription/new"
                >
                  souscrire à un abonnement
                </v-btn>
              </v-card-actions>
            </v-card>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </v-dialog>

    <!-- create new account  -->
    <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
      <span class="me-2">
        Vous avez deja un compte?
      </span>
      <a @click="$emit('back')">
        Me connecter
      </a>
    </v-card-text>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import {
  required, emailValidator, selected, lengthValidator,
} from '@/plugins/utils/validation'
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
import ProfileContestComponent from "@/components/ProfileContestComponent.vue";
import ProfileCreatorComponent from "@/components/ProfileCreatorComponent.vue";
import ProfilePostComponent from "@/components/ProfilePostComponent.vue";
import ImageUploadController from "@/components/ImageUploadController.vue";
import ProfileAvatarComponent from "@/components/ProfileAvatarComponent.vue";
import store from "@/store";

export default {
  components: {
    ProfileAvatarComponent,
    ImageUploadController,
    ProfilePostComponent,
    ProfileCreatorComponent,
    ProfileContestComponent,
    ChapterFormComponent},
  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const confirm_account = ref(false)
    const registration_completed = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const email = ref('')
    const password = ref('')
    const loader = ref(false)
    const e1 = ref(1)
    const tab = ref(1)
    const countries = ref([])
    const country = ref({})
    const user = ref({})
    const server_response = ref({
      error: {},
      error_message: '',
      snack: false,
      alert: false,
      loadactivation: false,
      loadregistration: false,
    })

    const resendcode = () => {
      Drequest.api(`resentactivationcode?user_id=${user.value.id}`)
        .get(response => {
          server_response.value.snack = true
        })
    }
    const init = () => {
      Drequest.api('lazyloading.country?dfilters=on&next=1&per_page=1000')
        .get(response => {
          countries.value = response.listEntity
        })
    }
    init()
    const validateAccount = () => {
      server_response.value.loadactivation = true
      Drequest.api(`user.activateaccount?user_id=${user.value.id}`)
        .toFormdata({ activationcode: user.value.activationcode })
        .post(response => {
          console.log(response)
          server_response.value.loadactivation = false
          if (response.success) {
            confirm_account.value = false
            registration_completed.value = true
            Drequest.setUser(user.value)
            server_response.value.astuce = response.astuce
            server_response.value.detail = response.detail
            store.commit('updateUser', {  is_activated: 1 })
            e1.value = 1

            // emit('logged', user.value, "")
          } else {
            server_response.value.error_message = response.detail
          }
        })
        .fail(e => {
          server_response.value.alert = true
          server_response.value.color = 'error'
          server_response.value.error_message = 'Oops. Nous n\'arrivons pas a nous connecter. Veuillez réessayer svp.' // response.detail
        })
    }

    const register = () => {
      // if (valid.value) {
      console.log('dfdfdfdfd')
      if (!user.value.email && !user.value.phonenumber) {
        server_response.value.error.email = 'Vous devez remplire le numero de telephone si vous n\'avez pas d\'adresse email'
        server_response.value.error.phonenumber = 'Vous devez renseigner l\'adresse mail si vous n\'avez pas de telephone'

        return
      }
      if (user.value.phonenumber && !country.value.id) {
        server_response.value.error.phonenumber = 'Vous devez selectionner un pays'

        return
      }

      server_response.value.loadregistration = true
      Drequest.api('user.register')
        .data({
          user: {
            email: user.value.email,
            phonenumber: user.value.phonenumber,
            password: user.value.password,
            username: user.value.username,
            country_iso: country.value.iso,
          },
        })
        .raw(response => {
          console.log(response)
          server_response.value.loadregistration = false
          if (!response.success) {
            server_response.value.error = response.error

            return
          }
          user.value = response.user
          store.commit('updateUser', user.value)
          confirm_account.value = true
        })
        .fail(e => {
          server_response.value.alert = true
          server_response.value.color = 'error'
          server_response.value.error_message = 'Oops. Nous n\'arrivons pas a nous connecter. Veuillez réessayer svp.' // response.detail
        })

      // } else {
      //   validate()
      // }
    }

    const profileUploaded = (profile) => {

    }

    return {
      register,
      validateAccount,
      resendcode,
      validate,
      profileUploaded,

      valid,
      form,
      isPasswordVisible,
      registration_completed,
      confirm_account,
      email,
      password,
      loader,
      server_response,
      e1,
      tab,
      countries,
      country,
      user,

      window : window,
      validators: {
        required,
        emailValidator,
        lengthValidator,
        selected,
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
