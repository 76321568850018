<template>
  <v-card elevation="0">
    <!-- login form -->

    <v-alert
      v-show="alert.active"
      dismissible
      :color="alert.color"
      border="left"
      elevation="2"
      colored-border
      :icon="alert.icons"
    >
      {{ alert.text }}
    </v-alert>
    <template>
      <v-stepper
        v-model="e6"
        vertical
        elevation="0"
      >
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
        >
          Etap 1
          <small>Envoyer le code d'activation</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-form @submit.prevent="sendactivationcode">
            <v-tabs
              v-model="tabs"
              class="mb-3"
              fixed-tabs
              @click="login = '';phonenumber = ''"
            >
              <v-tabs-slider></v-tabs-slider>

              <v-tab
                href="#mobile-tabs-5-1"
              >
                <v-icon>mdi-email</v-icon>
                Email
              </v-tab>

              <v-tab
                href="#mobile-tabs-5-2"
              >
                <v-icon>mdi-phone</v-icon>
                SMS
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabs">
              <v-tab-item
                :key="1"
                :value="'mobile-tabs-5-' + 1"
              >
                <v-text-field
                  v-model="login"
                  outlined
                  dense
                  :label="('e-mail')"
                  placeholder="john@example.com"
                  hide-details
                  class="mb-3"
                ></v-text-field>
              </v-tab-item>
              <v-tab-item
                :key="2"
                :value="'mobile-tabs-5-' + 2"
              >
                <v-row dense>
                  <v-col
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                  >
                    <v-select
                      v-model="country_iso"
                      dense
                      label="Pays"
                      :loading="loadcountry"
                      :items="countries"
                      item-text="name"
                      item-value="iso"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    lg="8"
                    md="8"
                    sm="12"
                    xs="12"
                  >
                    <v-text-field
                      v-model="phonenumber"
                      outlined
                      dense
                      :label="('Numero de telephone')"
                      hide-details
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
              <v-btn
                block
                :loading="loader"
                type="submit"
                small
                class="mt-1"
                color="primary"
              >
                Envoyer le code
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 2"
          step="2"
        >
          Entrer votre nouveau mot de passe
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card
            elevation="0"
            class="mb-12"
          >
            <v-otp-input
              v-model="activationcode"
              outlined
              dense
              :label="('Code')"
              length="5"
              hide-details
              class="mb-3"
            ></v-otp-input>
            <v-text-field
              v-model="activationcode"
              :label="('Entrer le Code reçu par email/SMS')"
              outlined
              dense
              counter="5"
              maxlenght="5"
              class="mb-3"
            ></v-text-field>
            <p>Vous n'avez pas recu de code? <a @click="e6 = 1">Réessayer</a></p>

            <v-text-field
              v-model="password"
              outlined
              dense
              class="mb-3"
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="('Nouveau mot de passe')"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="confirmpassword"
              outlined
              dense
              class="mb-3"
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="('Confirmer votre mot de passe')"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-card-actions class="pa-0">
              <v-btn
                block
                :loading="loader"
                type="submit"
                small
                class="mt-4"
                color="primary"
                @click="ressetpassword"
              >
                <v-icon
                  style="color: white"
                  size="20"
                >
                  {{ icons.mdiAccountCircle }}
                </v-icon>
                Reinitialiser mon mot de passe
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </template>
  </v-card>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountCircle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAlertRemove,
  mdiThumbUp,
} from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {

  data: () => ({
    activationcode: '',
    password: '',
    confirmpassword: '',
    e6: 1,
    tabs: 1,
    login: '',
    user: {},
    countries: [],
    country_iso: '',
    phonenumber: '',
    alert: {
      color: '',
      text: '',
      icons: mdiEyeOutline,
      active: false,
    },
    isPasswordVisible: false,
    loader: null,
    loadcountry: true,
    loading: false,
    icons: {
      mdiEyeOutline,
      mdiAccountCircle,
      mdiEyeOffOutline,
      mdiAlertRemove,
      mdiThumbUp,
    },
  }),
  beforeMount() {
    Drequest.api('lazyloading.country?dfilters=on&per_page=all&next=1')
      .get(response => {
        console.log(response)
        this.countries = response.listEntity
        this.loadcountry = false
      })
  },
  methods: {
    sendactivationcode() {
      const bodyFormData = new FormData()

      if (this.phonenumber) {
        bodyFormData.append('user_form[login]', this.phonenumber)
      } else {
        bodyFormData.append('user_form[login]', this.login)
      }

      bodyFormData.append('user_form[country_iso]', this.country_iso)

      this.loader = true

      Drequest.api('user.initresetpassword')
        .data(bodyFormData)
        .post(res => {
          console.log(res)

          // next step
          this.loader = null
          if (res.success) {
            this.user = res.user
            this.alert = {
              color: 'success',
              active: true,
              text: res.detail,
              icons: this.icons.mdiAlertRemove,
            }

            this.e6 = 2
          } else {
            this.alert = {
              color: 'error',
              active: true,
              text: res.error,
              icons: this.icons.mdiAlertRemove,
            }
          }
        })
        .catch(err => {
          this.alert = {
            color: 'error',
            active: true,
            text: err.login,
            icons: this.icons.mdiAlertRemove,
          }
          console.log(err)
        })
    },
    ressetpassword() {
      if (this.password !== this.confirmpassword) {
        this.alert = {
          color: 'error',
          active: true,
          text: 'les mots de passe ne sont pas identique',
          icons: this.icons.mdiAlertRemove,
        }
      }

      const bodyFormData = new FormData()
      bodyFormData.append('activationcode', this.activationcode)
      bodyFormData.append('password', this.password)

      this.loader = true
      Drequest.api(`user.resetpassword?user_id=${this.user.id}`)
        .data(bodyFormData)
        .post(res => {
          console.log(res)
          if (res.success) {
            this.alert = {
              color: 'success',
              active: true,
              text: res.detail,
              icons: this.icons.mdiThumbUp,
            }
            setTimeout(() => {
              this.loader = null
              this.$emit('reseted', this.user)

              // this.$router.push('/auth/login')
            }, 1500)
          } else {
            this.loader = null
            this.alert = {
              color: 'error',
              active: true,
              text: res.detail,
              icons: this.icons.mdiThumbUp,
            }
          }
        })
        .fail(err => {
          this.alert = {
            color: 'error',
            active: true,
            text: err.login,
            icons: this.icons.mdiAlertRemove,
          }
          console.log(err)
          this.loader = null
        })
    },
  },
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
