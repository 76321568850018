<template>
  <div class="">

    <v-card-title class="pa-2">
      Membres ({{membersll.nb_element}})
      <v-spacer></v-spacer>

    </v-card-title>
<!--        <v-card
          elevation="0"
          :loading="loader.loading"
        >
          <v-card-title>

          </v-card-title>
          <v-card-text class="pa-1 pt-3">
            <v-text-field
              dense
              label="Recherche ..."
              outlined
            ></v-text-field>
          </v-card-text>
        </v-card>-->

        <div
          v-if="loader.reload"
          class="pa-lg-6 pa-sm-3 text-center"
        >
          <v-btn
            class="ma-auto"
            color="primary"
            @click="init()"
          >
            Recharger
          </v-btn>
        </div>
        <div
          v-else
          class="pa-lg-6 pa-sm-3"
        >
          <v-row >
            <v-col
              v-for="(item, index) in membersll.listEntity"
              :key="index"
              class="d-flex child-flex"
              cols="4"
            >
              <profile-item
                :user="user" :profile="item"></profile-item>
            </v-col>
          </v-row>

          <v-row v-if="loader.loading">
            <v-col
              v-for="n in 9"
              :key="'new-'+n"
              class="d-flex child-flex"
              cols="4"
            >
              <v-sheet
                :color="`grey darken-2 `"
              >
                <v-skeleton-loader
                  class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3"
                  style="margin-left:3px; margin-right:3px; vertical-align: top"
                  :width="columns"
                  max-width="210"
                  min-width="108"
                  type="card"
                ></v-skeleton-loader>
              </v-sheet>
            </v-col>
          </v-row>

        </div>
    <v-card v-intersect="infiniteScrolling"></v-card>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
import {useRouter} from "@/utils";
import ProfileItem from "@/components/ProfileItem.vue";
// import { useMeta } from 'vue-meta'

export default {
  components: {ProfileItem, Banner},
  setup() {
    //useMeta({ title: 'Some Page' })
    const $vuetify = getVuetify()
    const loader = ref({
      loading: true,
      reload: false,
      followed: false,
    })
    const items = ref([])
    const membersll = ref({next:1})
    const drawer = ref(false)
    const tab = ref('follower')
    const {router} = useRouter()

    const route = router.currentRoute
    const idprofile = parseInt(route.params.idprofile)
    const user_id = ref((localStorage.getItem('user_id')))
    const user = ref(Drequest.getUser())

    const columns = computed(() => {
      if ($vuetify.breakpoint.md) {
        return '20%'
      }
      if ($vuetify.breakpoint.sm) {
        return '31%'
      }
      if ($vuetify.breakpoint.xs) {
        return '31%'
      }

      return '13%'
    })
    const init = (next) => {
      loader.value.reload = false
      loader.value.loading = true
      Drequest.api('lazyloading.user?dfilters=on&dsort=id desc&username:neq=')
        .param({
          per_page: 24,
          next: next,
          //...loader.value.options,
          //user_id: user.id,
          //'status._key:neq': 'draft',
          //...(idprofile ? { 'user.id:eq': idprofile } : {})
        })
        .get(response => {
          console.log(response)
          loader.value.loading = false

          if (next >= 2) {
            membersll.value.listEntity = [...membersll.value.listEntity, ...response.listEntity]
            membersll.value.next = response.next

          } else {
            membersll.value = response
          }
        })
        .fail(e => {
          loader.value.reload = true
        })
    }
    init(1)
    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loader.value.loading)
      if (loader.value.loading) return

      console.log(membersll.value.next >= 2, membersll.value)
      if (membersll.value.next >= 2) init(membersll.value.next)
    }

    return {
      detail,
      init,
      infiniteScrolling,

      user,
      loader,
      tab,
      items,
      membersll,
      columns,
      drawer,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
