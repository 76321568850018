<template>
  <div class="d-flex align-center">
    <v-avatar :size="size" class="mr-3">
      <v-img
        v-if="user.avatar"
        contain
        :src="require('@/assets/images/avatars/'+user.avatar)"
      ></v-img>
      <v-img
        v-else
        contain
        :src="require('@/assets/images/avatars/logo.png')"
      ></v-img>
    </v-avatar>
    <b>{{ user.username }}</b>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'

export default {
  props:['user', 'size'],
  setup() {

    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
