import Vue from 'vue'
import Vuex from 'vuex'
import {Drequest} from "@/plugins/Drequest";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    avatars: [
      {
        name: '3AG',
        path: 'logo.png',
      },
      {
        name: 'Postknight',
        path: 'aya-1.png',
      },
      {
        name: 'idaho',
        path: 'idaho.jpg',
      },
      {
        name: 'idaho',
        path: 'noah.jpg',
      },
      {
        name: 'idaho',
        path: 'tew-1.jpg',
      },
      {
        name: 'idaho',
        path: 'tew-2.png',
      },
      {
        name: 'idaho',
        path: 'tew-3.jpg',
      },
      {
        name: 'bakari shibi',
        path: 'me-1.jpg',
      },
      {
        name: 'moda',
        path: 'me-2.jpg',
      },
      {
        name: 'moda',
        path: 'me-3.jpg',
      },
      {
        name: 'moda',
        path: 'me-4.jpg',
      },
      {
        name: 'yuri hello',
        path: 'tew-4.jpg',
      },
      {
        name: 'willy dark',
        path: 'tew-5.jpg',
      },
      {
        name: 'Carla meguim',
        path: 'hk-1.jpg',
      },
      {
        name: 'Samael',
        path: 'tlk-1.jpg',
      },
      {
        name: 'Lune',
        path: 'tlk-2.jpg',
      },
    ],
    dark_mode: true,
    scanpages: [],
    timeStep: {
      secStep: 0,
      minStep: 0,
      hourStep: 0,
      dayStep: 0,
      weekStep: 0,
      monthStep: 0,
      yearStep: 0,
    },
    notify: {},
    metatag: {
      title: 'Plateforme Africaine de BD, Manga, Webtoons, Light novels et artbooks gratuit en ligne - 3agReader',
      content: "Publiez et lisez gratuitement vos mangas, bandes dessinées, webtoons et light novels en ligne. Découvrez des talents d'afrique et d'ailleurs, lisez-les, soutenez-les.",
      // all titles will be injected into this template
      titleTemplate: '%s | 3agReader - Lecture en ligne, Bande dessine, Manga, Comics,'
    },
    sub_id: null,
    authentication: false,
    user: {},
  },
  mutations: {
    updateScanpages(state, pages) {
      state.scanpages = pages
    },
    updatenotify(state, value) {
      state.notify = value
    },
    updateSubId(state, value) {
      state.sub_id = value
    },
    updateAuthentication(state, value) {
      state.authentication = value
    },
    updateMetatag(state, value) {
      console.log(value)
      state.metatag = value
    },
    updateTimeStep(state, value) {
      console.log(value)
      state.timeStep = value
    },
    updateUser(state, value) {
      console.log(value)
      if (value)
        state.user = {
          ...state.user,
          ...value
        }
      else
        state.user = {config:{}}

      localStorage.setItem('user_session', JSON.stringify(state.user))
      localStorage.setItem('user_id',  state.user.id )

    },
    updateTheme(state, value){

      localStorage.setItem('dark_mode', value)
      state.dark_mode = value

    }
  },
  actions: {
    sortScanpages(pages, callback) {

      var fd = new FormData();
      for (let img of pages) {
        fd.append("positions[]", img.id);
      }

      Drequest.api("chapterimage.sort")
        .data(fd)
        .post(callback)

    },
    initNotify({commit}) {

      Drequest.api('notificationbroadcasted.alertuser?dfilters=on&user_id=' + props.user_id)
        .get(response => {
          console.log(response)
          commit.updatenotify(response)
        })

    },
    notified() {
      if (!notify.value.unreaded) {
        return 0
      }

      var ids = []
      for (let el of notify.value.notifications) {
        ids.push(el.id)
      }

      Drequest.api('notified')
        .toFormdata({'ids': ids.join()})
        .post(response => {
          console.log(response)
          notify.value.unreaded = 0
        })

    },
    setAvatar  (avatar, user, callback)  {
      console.log(avatar)
      user.avatar = avatar.path
      // console.log(avatar.path)
      // return
      //changeavatar.value = false
      Drequest.setUser(user)

      Drequest.api(`update.user?id=${user.id}`)
        .data({
          user: {
            avatar: avatar.path,
          },
        })
        .raw(response => {
          console.log(response)

          // window.location.reload()
        })
    },
    changeTheme({commit, state}, vuetify) {
      vuetify.theme.dark = !state.dark_mode
      commit('updateTheme', !state.dark_mode)
    },
    init({commit}) {

      let secStep = 1000, minStep = secStep * 60, hourStep = minStep * 60, dayStep = hourStep * 24,
        weekStep = dayStep * 7, monthStep = weekStep * 4, yearStep = monthStep * 12;

      commit('updateTimeStep', {
        secStep: secStep,
        minStep: minStep,
        hourStep: hourStep,
        dayStep: dayStep,
        weekStep: weekStep,
        monthStep: monthStep,
        yearStep: yearStep,
      })

      commit('updateTheme', JSON.parse(localStorage.getItem('dark_mode')))

      const user_id = localStorage.getItem('user_id')
      if (user_id) {
        commit('updateUser', JSON.parse(localStorage.getItem('user_session')))
      }else{
        commit('updateUser', null)
      }

      const sub_id = localStorage.getItem('subscription_id')
      if (sub_id) {
        commit('updateSubId', sub_id)
      }
    },
    logout  ()  {
      Drequest.logout()
      window.location.href = '/auth/login'
    }
  },
  getters: {
    isLoggedIn(state){
      return  localStorage.getItem('user_id') ? true : false
    }
  },
  modules: {},
})
