<template>
  <div class="  mb-15">
    <div
    >
      <post-component
        v-if="!loader.loading"
        :quick_comment="false"
        :limit="15"
        :post-item="post"
        :user="user"
        @replyPost="(post, index)=>replyPost(post, index)"
        @openComment="()=>loader.comment = true"
        @imageViewer="(images)=> {postimages = images; loader.dialog = true}"
      ></post-component>
    </div>

    <!--        <comment-component
              :quick_comment="false"
              :limit="15"
              :user="user"
              :post_id="post.id"
              :nbcomment="post.nbcomment"
              @initlogin="()=>$emit('initlogin')"
            ></comment-component>

        <comment-component v-if="post.id"
                           :limit="10"
                           :user="user"
                           :post_id="(post.id)"
                           :nbcomment="post.nbcomment"
                           @initlogin="()=>$emit('initlogin')"
        ></comment-component>
        -->

    <div v-if="post.has_children">
      <post-child-component v-if="post.category.slug == 5" v-for="(child, $index) in post.children"
                            :key="'child-'+child.id"
                            :postimage="child.postimages[0]"
                            :postItem="child"
                            :user="user"
                            :limit="1"
                            :index="$index"
      ></post-child-component>
    </div>

    <v-dialog fullscreen style="z-index: 1002" v-model="loader.dialog">
      <image-viewer @close="()=>loader.dialog = false" :post="postdetail" :postimages="postimages"
      ></image-viewer>
    </v-dialog>

    <v-dialog
      v-model="loader.replypost"
      max-width="600px" persistent
    >
      <post-reply-form-component
        v-model="loader.replypost"
        :postedit="{}"
        :mainpost="post"
        @posted="posted"
        @close="cancelpost"
      ></post-reply-form-component>
    </v-dialog>

    <v-sheet
      v-if="loader.loading"
      :color="`grey darken-2 `"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        max-width="500"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiChevronLeft,
  mdiPlus,
  mdiDotsVertical,
  mdiBookmark,
  mdiShareVariant,
  mdiHeart,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiClose,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import {useRouter} from '@/utils'
import PostComponent from '@/components/PostComponent'
import ImageViewer from '@/components/ImageViewer'
import CommentComponent from '@/components/CommentComponent.vue'
import PostChildComponent from '@/components/PostChildComponent.vue'
import PostReplyFormComponent from '@/components/PostReplyFormComponent.vue'

export default {
  components: {
    PostReplyFormComponent,
    PostChildComponent,
    CommentComponent,
    ImageViewer,
    PostComponent
  },
  /*beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter',to, from)
    // appelée avant que la route vers le composant soit confirmée.
    // cette fonction n'a pas accès à l'instance du composant avec `this`,
    // car le composant n'a pas encore été créé quand cette interception est appelée !
  },*/
  /*beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate', to, from)
    // appelée quand la route qui fait le rendu de ce composant change,
    // mais que ce composant est utilisé de nouveau dans la nouvelle route.
    // Par exemple, pour une route avec le paramètre dynamique `/foo/:id`, quand nous
    // naviguons entre `/foo/1` et `/foo/2`, la même instance du composant `Foo`
    // va être réutilisée, et ce hook va être appelé quand cela arrivera.
    // ce hook a accès à l'instance de ce composant via `this`.
  },*/
  /*beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave',to, from)
    // appelée quand la route qui fait le rendu de ce composant est sur le point
    // d'être laissée en faveur de la prochaine route.
    // elle a accès à l'instance de ce composant via `this`.

  },*/
  setup() {
    const $vuetify = getVuetify()
    const loader = ref({
      loading: true,
      dialog: false,
      comment: false,
      replypost: false,
    })
    const post = ref({})
    const postdetail = ref({})
    const postimages = ref([])
    const children = ref([])
    const user = Drequest.getUser()

    const {router} = useRouter()
    const route = router.currentRoute
    let idpost = route.params.id
    if (!idpost) {
      idpost = route.query.id
      if (!idpost) idpost = route.query.post
    }
    const init = () => {
      Drequest.api(`detail.post?id=${idpost}${user.id ? `&user_id=${user.id}` : ''}`)
        .get(response => {
          console.log(response)
          loader.value.loading = false
          post.value = response.post
          if (post.value.children) {
            children.value = post.value.children
          }
        })
    }
    init()

    const posted = post => {
      console.log(post)
      loader.value.replypost = false
      children.value.unshift(post)

    }
    const replyPost = (post, index) => {
      console.log(post)
      loader.value.replypost = true
    }
    const cancelpost = () => {
      loader.value.replypost = false
    }

    return {
      posted,
      replyPost,
      cancelpost,

      user,
      post,
      postimages,
      loader,
      postdetail,

      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiBookmark,
        mdiShareVariant,
        mdiHeart,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.sticky-top-0 {

  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;

}
</style>
