<template>
    <div>
  
      <v-card
        elevation="0"
        class=""
      >
        <v-card-title class="pa-2">
          Concours | <router-link :to="'/contest/'+contest.id">{{ contest.cmstext.title }}</router-link>
          <v-spacer></v-spacer> 
          <v-btn v-if="newchapters[0]"
              :to="'/comicbook/'+newchapters[0].comicbook_seo+'/'+newchapters[0].comicbook_id"
            small
            color="primary"
          >
            Voir tout
          </v-btn>
        </v-card-title>
  
        <v-card-actions
          v-if="loader.reloadchapter"
          class="text-center"
        >
          <v-btn
            class="ma-auto"
            color="primary"
            @click="init()"
          >
            Recharger
          </v-btn>
        </v-card-actions>
        <template v-else>
          <v-sheet
            v-if="loader.news"
            class="mx-auto"
          >
            <v-slide-group
              multiple
              :show-arrows="showarrow"
            >
              <v-slide-item
                v-for="n in 9"
                :key="'lnew'+n"
                v-slot="{ }"
              >
                <v-card
                  :color="`grey darken-2 `"
                  class="pa-3"
                >
                  <v-skeleton-loader
                    class="mx-auto"
                    :height="slider_height.news"
                    :width="columns"
                    type="card"
                  ></v-skeleton-loader>
                </v-card>
              </v-slide-item>
  
              <!--          <v-slide-item v-else
                                      v-for="(item, index) in newchapters"
                                      :key="'new-'+index"
                                      v-slot="{ }"
                        >
                        </v-slide-item>-->
            </v-slide-group>
          </v-sheet>
          <v-sheet
            v-else
            class="mx-auto "
          >
            <v-slide-group
              multiple
              :show-arrows="showarrow"
            >
              <v-slide-item
                v-for="(item, index) in newchapters"
                :key="'new-'+index"
                v-slot="{ }"
              >
  
                <v-card
                  :rounded="false"
                  outlined
                  :to="'/scan/'+item.comicbook_seo+'/'+item.id"
                  min-width="108"
                  :width="columns"
                  class="ma-2"
                >
                  <v-img
                    class="white--text align-end"
                    :height="slider_height.news"
                    :src="item.cover_src"
                    :lazy-src="require('@/assets/images/holder.jpg')"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0 "
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-card-subtitle class="title-inline text-center pa-1">
                    {{ item.comicbook_name }} <v-spacer></v-spacer>#{{ item.number }}
                  </v-card-subtitle>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </template>
      </v-card>
  
    </div>
  </template>
  
  <script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiFacebook,
    mdiTwitter,
    mdiGithub,
    mdiGoogle,
    mdiEyeOutline,
    mdiEyeOffOutline, mdiCartOutline, mdiClose,
  } from '@mdi/js'
  import { computed, ref } from '@vue/composition-api'
  import { Drequest } from '@/plugins/Drequest'
  import { getVuetify } from '@/plugins/utils'
  
  export default {
    props:{
        contest: Object
    },
    setup(props) {
      const $vuetify = getVuetify()
      const isPasswordVisible = ref(false)
      const productdialog = ref(false)
      const email = ref('')
      const password = ref('')
      const items = ref([])
      const newchapters = ref([])
      const oneshotchapters = ref([])
      const product = ref({ shop: {} })
      const loader = ref({
        news: true,
        oneshotnews: true,
        products: true,
        reloadprod: false,
        reloadchapter: false,
        oneshotreloadchapter: false,
      })
      const mcs = ref([])
      const advertising = ref({})
      const socialLink = [
        {
          icon: mdiGithub,
          color: '#272727',
          colorInDark: '#fff',
        },
        {
          icon: mdiGoogle,
          color: '#db4437',
          colorInDark: '#db4437',
        },
      ]
      const columns = computed(() => {
        if ($vuetify.breakpoint.sm) {
          return 120
        }
        if ($vuetify.breakpoint.xs) {
          return 108
        }
  
        return 210
      })
  
      const showarrow = computed(() => {
        if ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) {
          return false
        }
  
        return true
      })
  
      // const user = Drequest.getUser()
  
      const init = () => {
        loader.value.reloadchapter = false
        loader.value.reloadprod = false
  
        Drequest.api('lazyloading.chapter?dfilters=on&next=1&per_page=9&dsort=id desc&contest.id:eq='+props.contest.id)// &user_id=269&subscription_id=1
          .get(response => {
            console.log(response)
            loader.value.news = false
            newchapters.value = response.listEntity
          })
          .fail(e => {
            loader.value.news = false
            loader.value.reloadchapter = true
            console.log(e)
          })
  
      }
      init()
      const slider_height = computed(() => {
        if ($vuetify.breakpoint.xl) {
          return {
            news: 270,
            product: 270,
          }
        }
  
        if ($vuetify.breakpoint.lg) {
          return {
            news: 270,
            product: 270,
          }
        }
  
        if ($vuetify.breakpoint.md) {
          return {
            news: 210,
            product: 210,
          }
        }
        if ($vuetify.breakpoint.sm) {
          return {
            news: 180,
            product: 180,
          }
        }
        if ($vuetify.breakpoint.xs) {
          return {
            news: 150,
            product: 150,
          }
        }
  
        return {
          news: 270,
          product: 270,
        }
      })
  
      return {
        init,
  
        isPasswordVisible,
        productdialog,
        advertising,
        product,
        loader,
        email,
        password,
        socialLink,
        items,
        newchapters,
        oneshotchapters,
        columns,
        mcs,
        showarrow,
        slider_height,
  
        icons: {
          mdiCartOutline,
          mdiClose,
          mdiEyeOutline,
          mdiEyeOffOutline,
        },
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
  </style>
  