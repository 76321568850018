<template>
  <v-card
    class="pt-15"
  >
    <v-row>
      <v-col
        lg="12"
        md="12"
        sm="12"
      >
        <v-card-title color="deep-purple darken-3">
          Mes favoris
        </v-card-title>

        <v-card-text>
        </v-card-text>

        <v-card-subtitle color="deep-purple darken-3">
          Chapitres
        </v-card-subtitle>
        <v-card-text>
          <v-list three-line>
            <template v-for="(item, index) in chapters">
              <v-divider
                :key="index"
              ></v-divider>

              <v-list-item
                :key="item.title"
                :to="'/scan/'+item.chapter.comicbook_alt+'/'+item.id"
              >
                <v-list-item-avatar :rounded="false">
                  <v-img :src="item.chapter.cover_src_sm"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title><b>#{{ item.chapter.number }}</b> {{ item.chapter.title }}</v-list-item-title>
                  <v-list-item-subtitle v-html="item.creationdate"></v-list-item-subtitle>
                  <v-list-item-action-text v-if="item.bought">
                    achat #{{ item.bought }}
                  </v-list-item-action-text>
                </v-list-item-content>
                <v-list-item-action>
                  <toggle-favorite-component
                    :chapter="{...item.chapter, isfavorite:parseInt(item.notify)}"
                    @update-notify="()=>item.notify = 1"
                  ></toggle-favorite-component>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiHeart,
  mdiHeartOutline,
  mdiInformationOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import { useRouter } from '@/utils'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'

export default {
  components: { ToggleFavoriteComponent },
  setup() {
    const $vuetify = getVuetify()

    const chapters = ref([])

    Drequest.api(`chapter.favorite?user_id=${localStorage.getItem('user_id')}`)
      .get(response => {
        console.log(response)
        chapters.value = response.listEntity
      })
    const slider_height = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 300
      }
      if ($vuetify.breakpoint.xs) {
        return 200
      }

      return 800
    })

    return {
      chapters,
      slider_height,

      icons: {
        mdiEyeOutline,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
