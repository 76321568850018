<script >

import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import Banner from "@/components/Banner.vue";

import {
  mdiEyeOffOutline, mdiEyeOutline, mdiInformationOutline, mdiSquareEditOutline,
  mdiCommentOutline,
  mdiClose
} from "@mdi/js";

export default {
  components: {FollowToggleComponent, Banner},
  props: {
    item: Object,
    owner:  {
      type: Boolean,
      default: () => {
        return false
      },
    },
    index:  {
      type: Number,
      default: () => {
        return 0
      },
    },
    dense:  {
      type: Boolean,
      default: () => {
        return true
      },
    },
  },
  data(){
    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
        mdiSquareEditOutline,
        mdiCommentOutline,
        mdiClose,
      },
    }
  }
}

</script>

<template>

  <v-list-item v-if="!owner" class="pa-0"
               :key="item.title"
               :to="'/scan/'+item.comicbook_seo+'/'+item.id"
               :dense="dense"
  >
    <img
      class="mr-3"
      width="60"
      :src="item.cover_src_sm"
    >

    <v-list-item-content>
      <v-list-item-title class="title-inline">
        <b>#{{ item.number }}</b> {{ item.title }}
        <v-spacer></v-spacer>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ item.creationdate }} <br>
        <span>
                <v-icon small>
                  {{ icons.mdiEyeOutline }}
                </v-icon>
                {{ item.nbviews }}</span>
        <span v-if="item.post_id" class="pl-3">
                <v-icon small>{{
                    icons.mdiCommentOutline
                  }}</v-icon> <b>{{ item.nb_comments }}</b>
                </span>
      </v-list-item-subtitle>

    </v-list-item-content>

    <v-list-item-icon>
    </v-list-item-icon>
    <v-list-item-action>
              <span
                v-if="item.product.price == 0"
                class="text--primary2  font-secondary"
              >
                Free
              </span>
      <template v-if="item.product.price > 0">
        <v-chip
          small
          color="warning"
        >
          {{ item.product.price }} Credits
        </v-chip>
        Extrait {{ item.free_pages }} pages
      </template>
      <!--                  <toggle-favorite-component :nbkola="item.nbkola" :chapter="item"></toggle-favorite-component>-->
    </v-list-item-action>
  </v-list-item>
  <v-list-item v-else
    :key="item.title"
    dense
  >
    <img
      class="mr-3"
      width="50"
      :src="item.cover_src_sm"
    >

    <v-list-item-content>
      <v-list-item-title class="title-inline">
        <b>#{{ item.number }}</b> {{ item.title }}
        <v-spacer></v-spacer>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ item.creationdate }}<br>
        <span>
          <v-icon small>
            {{ icons.mdiEyeOutline }}
          </v-icon>
                {{ item.nbviews }}</span>
        <v-btn rounded class="ml-3" v-if="item.post_id" small
               :to="'/activity/'+item.post_id">
          <v-icon small>{{ icons.mdiCommentOutline }}</v-icon>
          <b>{{ item.nb_comments }}</b>
        </v-btn>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon>
    </v-list-item-icon>
    <v-list-item-action>

      <template>
        <v-btn-toggle dense
                      variant="outlined"
                      divided
        >
          <v-btn color="primary"
                 :to="'/scan/'+item.comicbook_seo+'/'+item.id" small>Lire
          </v-btn>
          <v-btn small icon :to="'/chapter/form/'+item.id">
            <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
          </v-btn>
          <v-btn small icon @click="$emit('deleteItem', item, index)">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-btn-toggle>

      </template>
      <span
        v-if="item.product.price == 0"
        class="text--primary2  font-secondary"
      >
                Free
              </span>
      <template v-if="item.product.price > 0">
                <span class="text--accent-1  font-secondary "
                >
                  {{ item.product.price }} Credits
                </span>
        Extrait {{ item.free_pages }} pages
      </template>
      <!--                  <toggle-favorite-component :nbkola="item.nbkola" :chapter="item"></toggle-favorite-component>-->
    </v-list-item-action>
  </v-list-item>

</template>

<style scoped lang="scss">

</style>
