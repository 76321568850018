<template>
  <div class="">
    <div class="">
      <v-card class="">
        <!-- logo -->
        <v-card-title class="d-flex py-7 position-relative">
          FAQ
        </v-card-title>

        <!-- login form -->
        <v-expansion-panels focusable>
          <v-expansion-panel
            v-for="(item,i) in faqs"
            :key="i"
          >
            <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
            <v-expansion-panel-content v-html="item.content"></v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import WalletRefillComponent from '@/components/WalletRefillComponent'

export default {
  setup() {
    const faqs = ref([])

    const init = () => {
      Drequest.api('lazyloading.tree-item?dfilters=on')
        .param({
          'tree.name:eq': 'faq',
        }).get(response => {
          console.log(response)
          faqs.value = response.listEntity
        })
    }
    init()

    return {
      faqs,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
