import { render, staticRenderFns } from "./TopViewItemComponent.vue?vue&type=template&id=46e4eb8e&scoped=true&"
import script from "./TopViewItemComponent.vue?vue&type=script&lang=js&"
export * from "./TopViewItemComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46e4eb8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCard,VChip,VIcon,VImg,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSpacer})
