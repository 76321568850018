<template>
  <div class="card">
    <v-list-item
      :key="oburl"
      class=" handle bordered"
      dense
    >
      <img
        class="mr-3"
        width="50"
        :src="oburl"
      >

      <v-list-item-content>
        <v-list-item-title class="title-inline">
          {{ thisfile.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ (thisfile.size / 1024 / 1024).toFixed(2) }} Mo
        </v-list-item-subtitle>
        <v-progress-linear v-if="loading"
                          v-model="progressionval"
                           :buffer-value="progressionmax"
        ></v-progress-linear>
      </v-list-item-content>
      <v-list-item-action>

        <v-btn-toggle v-if="retry" dense>
          <v-btn fab
                 x-small
                 color="warning"
                 @click="upload(); retrycounter = 0"
          >
            <v-icon>{{ icons.mdiRedo }}</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-btn-toggle v-if="thisfile.id" dense>
          <v-btn fab
                 x-small
                 color="error"
                 @click="remove"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
<!--          <v-btn fab
                 x-small
                 class=" ml-2 "
          >
            <v-icon>{{ icons.mdiArrowUpDown }}</v-icon>
          </v-btn>-->
        </v-btn-toggle>
      </v-list-item-action>
    </v-list-item>

  </div>
</template>

<script>
import { Drequest } from '@/plugins/Drequest'
import $ from 'jquery'
import {
  mdiAccountGroupOutline, mdiArrowUpDown, mdiChevronLeft,
  mdiClose,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileImageOutline,
  mdiFolderAlert,
  mdiRedo
} from '@mdi/js'

export default {
  name: 'PostImageFormController',
  data() {
    return {
      oburl: '',
      cover: '',
      thisfile: {},
      progressionmax: 0,
      progressionval: 0,
      retrycounter: 0,
      loading: false,
      retry: false,
      icons: {
        mdiClose,
        mdiFolderAlert,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFileImageOutline,
        mdiAccountGroupOutline,
        mdiArrowUpDown,
        mdiChevronLeft,
        mdiRedo,
      },
    }
  },
  props: ['image', 'post', 'index'],
  beforeMount() {
    if (this.image.id) {
      this.thisfile = this.image
    } else {
      this.thisfile = this.image.file
    }
  },
  mounted() {
    // if (!idprod) {
    //     this.upload();
    // }
    console.log(this.image)
    this.cover = this.post.cover
    this.addimages()

  },
  methods: {
    addimages: function () {
      if (this.image.id) {
        this.oburl = this.image.show50
        return 0
      }
      this.oburl = window.URL.createObjectURL(this.image.file)
      this.retry = false
      this.upload()

    },
    oncomplete(response) {

      this.loading = false
      console.log(response)
      if (!response.success) {
        // alert("Un probleme est survenu. Veuillez reessayer SVP.")
        //this.$emit('removeimage', this.thisfile, this.index)
        //postmanagervue.removeimage(null, this.index)
        this.retrycounter++
        if(this.retrycounter < 3){
          // alert(this.retrycounter)
          this.upload()
          return  null
        }
        this.retry = true
        return null
      }
      this.thisfile.id = response.postimage.id
      /* this.thisfile = {
        ...this.thisfile,
        ...response.postimage,
      } */
      //if (!this.post.id) {
        //this.uploaded()
        this.$emit('uploaded')
      //}

    },
    upload() {
      var el = this.$el
      console.log(this.image)
      this.loading = true
      this.retry = false
      var form = new FormData()
      form.append('image', this.image.file)
      Drequest.api(`postimage.upload?idpost=${this.post.id}`)
        .data(form)
        .upload((loaded, total) => {

            this.progressionval = ((loaded / total) * 100)
            this.progressionmax = 100

          },
          this.oncomplete)


      return 0
     /*  Drequest.upload(this.image.file, Drequest.__env + `api/postimage.upload?idpost=${this.post.id}`,
        function (loaded, total) {
          console.log(loaded, total)
          $(el)
            .find('.progress-bar')
            .css({
              'width': ((loaded / total) * 100) + '%',
              'transition': '.3s',
            })
          el.progressionval = loaded
          el.progressionmax = total
          //$(this.$el).find(".progress").eq(index)[0].max = total;
        },
        function () {
        }, this.oncomplete, 'json', 'image'
      ) */
    },
    remove() {
      if (!confirm('Confirmez la supprission de l\'image')) {
        return
      }

      this.$emit('removeimage', this.thisfile, this.index)
      //postmanagervue.removeimage(this.thisfile, this.index)
    }
  }, // thisfile.type
}
</script>

<style scoped>

</style>
