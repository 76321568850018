<template>
<span>
                <v-btn v-if="follow" @click="toggleFollow"
                       :loading="loader.follow" small rounded outlined
                >
                  Se desabonner
                </v-btn>
                <v-btn v-else @click="toggleFollow" rounded
                       variant="outlined" color="primary"
                       :loading="loader.follow"
                >
                  S'abonner
                </v-btn>

</span>
</template>

<script>
import { Drequest } from '@/plugins/Drequest'
import { computed, ref } from '@vue/composition-api'
import {
  mdiComment,
  mdiDotsVertical, mdiEyeOffOutline,
  mdiEyeOutline,
  mdiHeart,
  mdiHeartOutline,
  mdiShareVariant
} from '@mdi/js'

export default {
  name: 'ComicbookFollowToggleComponent',
  props: {
    'status': Number,
    user_id: {
      type: Number,
      default: () => null,
    },
    comicbook_id: {
      type: Number,
      default: () => null,
    },
  },
  mounted() {
    this.follow = this.status
  },
  setup(props, { emit }) {
    const follow = ref(false)

    const loader = ref({
      loading: true,
      follow: false,
    })
    const toggleFollow = () => {
      if (props.user_id) {
        loader.value.follow = true
        Drequest.api('comicbook.subscription')
          .param({user_id:props.user_id, cb_id:props.comicbook_id})
          .get(response => {
            console.log(response)
            loader.value.follow = false
            follow.value = !follow.value
            emit('subscribed', !follow.value, { nbkola: response.nbkola })
          })
      } else {
        alert('Vous devez etre connecte pour vous abonner ')
        emit('initlogin')
      }
    }
    return {
      loader,
      follow,
      toggleFollow,
      icons: {
        mdiDotsVertical,
        mdiComment,
        mdiShareVariant,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  }
}
</script>

<style scoped>

</style>
