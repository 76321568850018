<script>

import TopViewItemComponent from "@/components/TopViewItemComponent.vue";
import {ref} from "@vue/composition-api";
import {Drequest} from "@/plugins/Drequest";
import {mdiStarOutline} from "@mdi/js";

export default {
  components: {TopViewItemComponent},
  setup() {

    const comicbook_topviews = ref([])
    const filter = ref({groupby:'view',
      date_from: '',
      date_to: ''})

    const loader = ref({
      news: true,
      oneshotnews: true,
      products: true,
      reloadprod: false,
      reloadchapter: false,
      oneshotreloadchapter: false,
    })
    const init = (next, filterv) => {
      loader.value.reloadchapter = false
      loader.value.reloadprod = false
      comicbook_topviews.value = []

      Drequest.api('comicbook.ranking')
        .param({
          filter: filterv,
          date_from: filter.value,
          date_to: filter.value,
        })
        .get(response => {
          console.log(response)
          comicbook_topviews.value = response.comicbook_topviews
        })

    }
    init(1, 'views')
    return {
      init,

      loader,
      filter,
      comicbook_topviews,
      icons:{
        mdiStarOutline
      }
    }
  }
}
</script>

<template>

  <v-card elevation="0">
    <v-card-title>
      <v-icon class="mr-3">{{ icons.mdiStarOutline }}</v-icon>
      Classement
      <v-spacer></v-spacer>

    </v-card-title>
    <v-card-text>
      <v-chip-group>
      <v-chip @click="init(1, 'views')">Classement par nombre de vue</v-chip>

      <v-chip @click="init(1, 'rating')">Classement par note</v-chip>
    </v-chip-group>
    </v-card-text>
    <v-card elevation="0" v-if="comicbook_topviews.length === 0" v-for="n in 3"
            :color="`grey darken-2 `"
            :key="n"
    >
      <v-skeleton-loader
        class="mx-auto"
        :height="90"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </v-card>
    <v-card-text style="position: relative" class="position-relative">
      <v-row>
        <template v-for="(item, index) in comicbook_topviews">
          <v-col cols="12" lg="6" md="6">
            <!--            <v-divider
                          :key="index"
                        ></v-divider>-->
            <v-list>
              <top-view-item-component
                :key="'item'+item.id"
                :item="item"
                :index="index"
              ></top-view-item-component>
            </v-list>
          </v-col>
        </template>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">

</style>
