<template>

  <v-sheet elevation="0"
           v-if="loader.detail"
           :color="`grey darken-2 `"
           class="pa-0"
  >
    <v-skeleton-loader
      class="mx-auto"
      width="100%"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
  <v-card v-else>
    <v-card-title>
      {{comicbook.name}}
      <v-spacer></v-spacer>
      <v-btn color="secondary"  small
             class="mr-3"
             :to="`/comicbook/${comicbook.nameseo}/`+comicbook.id">
        Previsualiser
      </v-btn>
      <v-btn color="warning" small
             :to="'/comicbook/form/'+comicbook.id">
        <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
        Editer
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-3" v-if="comicbook.id" >
      <comicbook-statistic-component
        :user_id="user_id"
        :comicbook="comicbook"
      ></comicbook-statistic-component>
    </v-card-text>
    <v-row dense >
      <v-col cols="12"  >
        <v-tabs class=" position-sticky font-secondary"
                v-model="tab" center-active
                align-tabs="center"
                grow
        >
          <v-tab
            key="illustrations"
          >
            Chapitres ({{ comicbook.nbchapter }})
          </v-tab>
          <v-tab
            key="series"
          >
            MiniChap ({{ comicbook.nbminichapter }})
          </v-tab>
          <v-tab
            key="oneshot"
          >
            Actualites
          </v-tab>
          <v-tab
            key="comment"
          >
            Commentaires
          </v-tab>
        </v-tabs>

        <template>

          <v-tabs-items v-model="tab">
            <v-tab-item
              key="illustrations"
            >
              <comic-chapter-component v-if="comicbook.id"
                                       :comicbook="comicbook"
                                       :user_id="user_id"
                                       :model="'complete'"
              ></comic-chapter-component>
            </v-tab-item>
            <v-tab-item
              :key="'series'"
            >

              <comic-chapter-component
                :comicbook="comicbook"
                :user_id="user_id"
                :model="'minichap'"
              ></comic-chapter-component>

            </v-tab-item>
            <v-tab-item
              :key="'oneshot'"
              style="min-height: 500px"
            >

              <comicbook-creator-activity-component
                v-if="comicbook.id"
                :user="user"
                :comicbook="comicbook"
                :parent_id="comicbook.post_id"
              ></comicbook-creator-activity-component>
            </v-tab-item>
            <v-tab-item
              :key="'comment'"
              style="min-height: 500px"
            >

              <comicbook-comment-resume-component
                :comicbook="comicbook"
                :user="user"
                :url="`comicbook?cb_id=${comicbook.id}`"
              ></comicbook-comment-resume-component>
            </v-tab-item>

          </v-tabs-items>
        </template>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiSquareEditOutline,
  mdiExclamation,
  mdiInformationOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import {useRouter} from '@/utils'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'
import ProfileCreatorComponent from "@/components/ProfileCreatorComponent.vue";
import CreatorSeriesComponent from "@/components/creator/CreatorSeriesComponent.vue";
import ProfilePostComponent from "@/components/ProfilePostComponent.vue";
import ComicChapterComponent from "@/components/comicbook/ComicChapterComponent.vue";
import PostReplyFormComponent from "@/components/PostReplyFormComponent.vue";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ComicbookFollowToggleComponent
  from "@/components/comicbook/ComicbookFollowToggleComponent.vue";
import CommentComponent from "@/components/CommentComponent.vue";
import CommentItemComponent from "@/components/CommentItemComponent.vue";
import ComicbookCommentResumeComponent
  from "@/components/comicbook/ComicbookCommentResumeComponent.vue";
import store from "@/store";
import ComicbookCreatorActivityComponent
  from "@/components/comicbook/ComicbookCreatorActivityComponent.vue";
import PostComponent from "@/components/PostComponent.vue";
import ComicbookStatisticComponent from "@/components/comicbook/ComicbookStatisticComponent.vue";
import ReadMore from "@/components/ReadMore.vue";

export default {
  components: {
    ReadMore,
    ComicbookStatisticComponent,
    PostComponent,
    ComicbookCreatorActivityComponent,
    ComicbookCommentResumeComponent,
    CommentItemComponent,
    CommentComponent,
    ComicbookFollowToggleComponent,
    FollowToggleComponent,
    PostReplyFormComponent,
    ComicChapterComponent,
    ProfilePostComponent,
    CreatorSeriesComponent,
    ProfileCreatorComponent,
    ToggleFavoriteComponent
  },
// child
  metaInfo() {
    return {
      title: `${this.comicbook.name}`,
      meta: [

        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: Drequest.__env+`comicbook/${this.comicbook.nameseo}/${this.comicbook.id}`
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: `${this.comicbook.synopsis}`
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: `${this.comicbook.cover_src}`
        },

      ]
    }
  },
  setup(props, {emit}) {
    const $vuetify = getVuetify()

    const {router} = useRouter()
    const readMoreActivated = ref(false)
    const route = router.currentRoute
    const {id} = route.params
    const comicbook = ref({})
    const loader = ref({
      detail: true,
      loading: true,
    })
    const dialog = ref({
      newpost: false,
      replypost: false,
      imageviewer: false,
      authentication: false,
      commentpost: false,
      postkola: false,
    })
    const chapters = ref([])
    const tab = ref(0)

    const user_id = localStorage.getItem('user_id')
    const user = ref(Drequest.getUser())
    let url = ''
    if (user_id) {
      url = `&user_id=${user_id}`
    }
    loader.value.detail = true
    Drequest.api(`detail.comicbook?id=${id}` + url)
      .get(response => {
        console.log(response)
        loader.value.detail = false
        comicbook.value = response.comicbook

        emit('setTitle', comicbook.value.name, `/comicbook/${comicbook.value.nameseo}/${comicbook.value.id}`)
      })

    if (store.state.sub_id) {
      url += `&subscription_id=${store.state.sub_id}`
    }

    Drequest.api(`chapter.lazyloading?dfilters=on&next=1&dsort=id desc&per_page=3&status:eq=1&comicbook_id:eq=${id}${url}`)
      //.param({next: page})
      .get(response => {
        console.log(response)
        chapters.value = response.listEntity
      })
    /*

        loader.value.loading = true
        Drequest.api(`comicbook.complete-data?cb_id=${id}${url}`)
          .param({
          })
          .get(response => {
            console.log(response)
            loader.value.loading = false
            lastcomments.value = response.lastcomments
          })
    */

    const onSubscribed = (response) => {
      console.log(response)
    }

    const slider_height = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 200
      }
      if ($vuetify.breakpoint.xs) {
        return 100
      }

      return 300
    })

    return {
      onSubscribed,

      dialog,
      loader,
      user_id,
      user,
      comicbook,
      chapters,
      slider_height,
      tab,
      readMoreActivated,

      icons: {
        mdiSquareEditOutline,
        mdiExclamation,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
