<template>

      <v-main >
        <v-card max-width="1200"
          class="overflow-hidden ma-auto"
        >
          <v-responsive>
            <router-view></router-view>
          </v-responsive>
        </v-card>
        <v-divider class="border-opacity-25 mt-6 mb-6" ></v-divider>
        <footer-component></footer-component>
        <v-dialog
          v-model="dialog"
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Settings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  variant="text"
                  @click="dialog = false"
                >
                  Save
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            container
          </v-card>
        </v-dialog>
      </v-main>


</template>

<script>
import {
  mdiAccountOutline,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiCogOutline,
  mdiGamepadCircleOutline,
  mdiCreation,
  mdiAccountGroupOutline,
  mdiGrid,
  mdiAccessPoint,
  mdiEyeOffOutline,
  mdiFacebook,
  mdiYoutube,
  mdiInstagram,

} from '@mdi/js'
import FlatScreen from '@/layouts/components/FlatScreen'
import BottomNavigation from '@/layouts/components/BottomNavigation'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import AppBarUserMenu from "@/layouts/components/AppBarUserMenu.vue";
import VerticalNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import PWAPrompt from "@/layouts/components/PWAPrompt.vue";
import FooterComponent from "@/layouts/components/FooterComponent.vue";

export default {
  components: {
    FooterComponent,
    PWAPrompt, VerticalNavMenu, AppBarUserMenu, BottomNavigation, FlatScreen},

  props: {
     user: {
      type: Object,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    isDrawerOpen: false,
    bnav: 'home',
    links: [ //'Accueil',
      {name: 'A propos de 3ag edition', to: "https://3ag-edition.com/about-us"},
      {name: 'Se faire editer', to: "https://3ag-edition.com/fr/how-to-publish"},
      {name: 'Boutique en ligne', to: "https://buyamsellam24.com/fr/shops"},
      //'Se faire editer', 'Nos Points de vente', 'Boutique en ligne', 'Nous contacter'
    ],
    icons: {
      mdiAccountOutline,
      mdiTwitter,
      mdiGithub,
      mdiGoogle,
      mdiFacebook,
      mdiInstagram,
      mdiYoutube,
      mdiEyeOutline,
      mdiCogOutline,
      mdiCreation,
      mdiGrid,
      mdiAccountGroupOutline,
      mdiAccessPoint,
      mdiGamepadCircleOutline,
      mdiEyeOffOutline,
    },
    height: window.innerHeight,
  }),

  mounted() {
    this.$nextTick(() => {
      // window.addEventListener('resize', this.onResize)
    })
    //this.user = Drequest.getUser()
    console.log(this.user)
  },

  beforeDestroy() {
    // window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      // this.height = window.innerHeight
    },
  },

}
</script>

<style>
#bottom-navigation {
  position: fixed;
}
</style>
