<template>
  <div>

    <v-card
      elevation="0"
      class="mb-3"
    >
      <v-card-title class="pa-2">
        Mini collector
        <v-spacer></v-spacer>
        <v-btn
          href="https://buyamsellam24.com/fr/shop/3ag-edition"
          target="_blank"
          small
          color="primary"
        >
          Voir plus
        </v-btn>
      </v-card-title>

      <v-sheet
        v-if="loader.products"
        class="mx-auto"
      >
        <v-slide-group
          multiple
          :show-arrows="showarrow"
        >
          <v-slide-item
            v-for="n in 9"
            :key="'lprod'+n"
            v-slot="{ }"
          >
            <v-card
              :color="`grey darken-2 `"
              class="pa-3"
            >
              <v-skeleton-loader
                class="mx-auto"
                :height="250"
                :width="columns"
                type="card"
              ></v-skeleton-loader>
            </v-card>
          </v-slide-item>

          <!--          <v-slide-item
            v-else
            v-for="(item, index) in mcs"
            :key="'prod-'+index"
            v-slot="{ }"
          >
          </v-slide-item>-->
        </v-slide-group>
      </v-sheet>
      <v-tabs
        v-else
        class="pa-0"
        center-active
        :show-arrows="showarrow"
      >
        <v-tab
          v-for="(item, index) in mcs"
          :key="'prod-'+index"
          class="pa-0"
        >
          <v-card
            elevation="0"
            :width="columns"
            class="ma-2"
          >
            <v-img
              width="100%"
              :src="item.showcover"
              :lazy-src="require('@/assets/images/holder.jpg')"
              @click="productdialog = true; product = item"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-subtitle class="title-inline pa-1 text-center">
              {{ item.name }}
            </v-card-subtitle>
          </v-card>
        </v-tab>
      </v-tabs>
    </v-card>

    <v-dialog
      v-model="productdialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ product.name }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="error"
            @click="productdialog = false; product = {shop:{}}"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          :src="product.fullcover"
          :lazy-src="product.showcover"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card-title>{{ product.priceofsale }} XAF</v-card-title>
        <v-card-text v-html="product.description"></v-card-text>
        <v-card-actions>
          <v-btn
            block
            class="v-btn primary"
            :href="product.url"
            target="_blank"
          >
            <v-icon>{{ icons.mdiCartOutline }}</v-icon>
            Commander
          </v-btn>
        </v-card-actions>
        <v-card-actions class="text-center">
          <a
            :href="'https://buyamsellam24.com/fr/shop/'+product.shop.shopnameunique"
            target="_blank"
          >Visiter la boutique</a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline, mdiCartOutline, mdiClose,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'

export default {
  setup() {
    const $vuetify = getVuetify()
    const isPasswordVisible = ref(false)
    const productdialog = ref(false)
    const email = ref('')
    const password = ref('')
    const items = ref([])
    const newchapters = ref([])
    const oneshotchapters = ref([])
    const product = ref({ shop: {} })
    const loader = ref({
      news: true,
      oneshotnews: true,
      products: true,
      reloadprod: false,
      reloadchapter: false,
      oneshotreloadchapter: false,
    })
    const mcs = ref([])
    const advertising = ref({})
    const socialLink = [
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 120
      }
      if ($vuetify.breakpoint.xs) {
        return 108
      }

      return 210
    })

    const showarrow = computed(() => {
      if ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) {
        return false
      }

      return true
    })

    // const user = Drequest.getUser()

    const init = () => {
      loader.value.reloadchapter = false
      loader.value.reloadprod = false

      Drequest.init('https://buyamsellam24.com/api/lazyloading.product?dfilters=on&next=1&per_page=9&dsort=id desc&dlang=fr&shop_id:eq=33')
        .get(response => {
          console.log(response)
          loader.value.products = false
          mcs.value = response.listEntity
        })
        .fail(e => {
          loader.value.news = false
          loader.value.reloadprod = true
          console.log(e)
        })

    }
    init()

    return {
      init,

      isPasswordVisible,
      productdialog,
      advertising,
      product,
      loader,
      email,
      password,
      socialLink,
      items,
      newchapters,
      oneshotchapters,
      columns,
      mcs,
      showarrow,

      icons: {
        mdiCartOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
