<template>

  <v-list-item
    v-if="model === 'chapter'"
    :key="item.title"
    dense class="align-start"
    :to="'/scan/'+item.cb_nameseo+'/'+item.c_id"
  >
    <v-img v-if="item.c_cover"
           class="mr-3"
           max-width="50"
           :src="item.cover"
           :lazy-src="require('@/assets/images/holder.jpg')"></v-img>
    <img v-else
         class="mr-3"
         width="50"
         :src="require('@/assets/images/holder.jpg')"
    >
    <v-list-item-content>
      <v-list-item-title class="title-inline">
        <b>{{ item.username }}</b>
        <v-spacer></v-spacer>
      </v-list-item-title>
      {{item.comment}}

      <v-list-item-subtitle>
        <v-chip small color="warning">
          <b class="mr-1">#{{ item.cb_name }}</b> - {{ item.c_title }} </v-chip>
        <!-- - <v-icon small>
        {{ icons.mdiEyeOutline }}
      </v-icon> {{ item.nbviews }}-->
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ item.created_at }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
    </v-list-item-action>

  </v-list-item>
  <div v-else >
    <v-list-item dense
                 :key="item.id" class="align-start"
    >
      <user-avatar
        class="mr-3" :active="false" :user="item.user" :size="42"></user-avatar>
<!--      <v-img v-if="item.c_cover"
             class="mr-3"
             max-width="50"
             :src="item.cover"
             :lazy-src="require('@/assets/images/holder.jpg')"></v-img>
      <img v-else
           class="mr-3"
           width="50"
           :src="require('@/assets/images/holder.jpg')"
      >-->
      <v-list-item-content>
        <p class=" ">
        <b class="mr-3">{{ item.username }}</b>
        <span class="text-sm" v-html="item.comment"></span>

<!--        <v-list-item-title class="d-flex align-center">

          <username-component
          :user="item.user" :size="36"></username-component>
          <v-spacer></v-spacer>-->
          <br>
          <small class="mr-3 v-size&#45;&#45;small"><duration :last-date="item.created_at"></duration></small>
        </p>
<!--        <v-list-item-subtitle>
          <v-spacer></v-spacer>
        </v-list-item-subtitle>-->
        <v-list-item-subtitle class="d-flex justify-space-between align-center">
<!--          <v-btn @click="$emit('reply', item)" x-small link text>- repondre</v-btn>-->
          <v-btn @click="item.nbreply = loadreply = true" x-small link text>- repondre</v-btn>

          <small class="  v-size--x-small">
            <v-menu
              v-if="item.user.id == user.id"
              top
              left
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  small
                  v-on="on"
                  class="mr-3"
                >
                  <v-icon class="mr-1">
                    {{ icons.mdiDotsHorizontal }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <template>
                  <v-list-item
                    color="error"
                    @click="$emit('delete', item, index)"
                  >
                    <v-list-item-title>Supprimer</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <kola-toggle-component
              :subject="'postcomment'"
              :identify="parseInt(item.id)"
              :status="parseInt(item.liked)"
              :user_id="user.id"
              :counter="parseInt(item.nbkola)"
              @liked="(liked, nbkola)=>{item.liked = liked; item.nbkola = nbkola}"
            ></kola-toggle-component>
          </small>
        </v-list-item-subtitle>

        <span class="text-xs"
              v-if="item.nbreply > 0"
              @click=" loadreply = true"
        >--- Afficher les reponses {{ item.nbreply }}</span>

      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="item.nbreply && loadreply"
                 :key="'r-'+item.id"
                 class="px-0 pl-12"
    >
      <v-list-item-content>
        <comment-response-list-component
          :user="user"
          :limit="3"
          :parent_id="item.id"
          :newcomment="item"
          :nbreply="item.nbreply"
          @reply="$emit('reply', item)"
        ></comment-response-list-component>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiCommentAlertOutline,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import CommentFormComponent from '@/components/CommentFormComponent'
import CommentResponseListComponent from '@/components/CommentResponseListComponent'
import KolaToggleComponent from '@/components/KolaToggleComponent'
import UsernameComponent from "@/components/UsernameComponent.vue";
import Duration from "@/components/Duration.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
    Duration,
    UsernameComponent,
    KolaToggleComponent,
    CommentResponseListComponent,
    CommentFormComponent
  },
  props: {
    index: Number,
    item: {
      type: Object,
      default: () => {

      },
    },
    model: {
      type: String,
      default: () => {
        return 'any'
      },
    },
    user: {
      type: Object,
      default: () => {

      },
    },
    commentresponse : {
      type: Object,
      default: () => {

      },
    },
  },
  data (){
    return{
      loadreply: false
    }
  },
  setup(props) {
    const $vuetify = getVuetify()

    return {

      icons: {
        mdiDotsHorizontal,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiDotsVertical,
        mdiCommentAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

/*.comment-form {
  position: absolute;
  bottom: 0;
  background: white;
}*/
</style>
