<template>
  <div class=" pt-3">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card :to="'/activity/'+post.id"
          class="mb-3 mx-auto"
          max-width="600" elevation="0"
        >
          <v-img
            :src="postimage.uploaddir+'270_'+postimage.postimage"
            height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 "
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
              color="secondary"
            >
              <v-row>
                <v-col class="text--primary">
                  <v-icon>{{ icons.mdiHeartOutline }}</v-icon>
                  {{post.nbkola}}
                </v-col>
                <v-col class="text--primary">
                  <v-icon>{{ icons.mdiCommentOutline }}</v-icon>
                  {{post.nbcomment}}
                </v-col>
              </v-row>
            </v-overlay>
          </v-fade-transition>

        </v-card>
      </template>
    </v-hover>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDotsVertical,
  mdiCommentOutline,
  mdiComment,
  mdiShareVariant,
  mdiHeartOutline,
  mdiHeart,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'

export default {
  props: {
    postimage: Object,
    postItem: Object,
    user: Object,
    limit: Number,
    index: Number,
    loadreply: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const $vuetify = getVuetify()
    const loader = ref({
      loading: true,
      like: false,
    })

    const post = ref(JSON.parse(JSON.stringify(props.postItem)))

    const toggleKola = () => {
      if (props.user.id) {
        loader.value.like = true
        Drequest.toggleKola('post', post.value.id, post.value.liked)
          .post(response => {
            console.log(response)
            loader.value.like = false
            post.value.liked = !post.value.liked
            post.value.nbkola = response.nbkola
          })
      } else {
        alert('Vous devez etre connecte pour liker')
      }
    }
    const copytoclipboard = () => {
      navigator.clipboard.writeText(`https://reader.3ag-edition.com/activity/${post.value.id}`)
      alert('Le lien de la publication a bien ete copier dans le presse papier!')
    }
    const deletePost = () => {
      if (!confirm('Cette publication va etre supprime.\n\n Vous confirmez cette action?')) {
        return 0
      }

      Drequest.api(`post.delete?id=${post.value.id}`)
        .get(response => {
          console.log(response)
          alert(response.detail)
          emit('deleted', props.index)
        })
        .fail(e => {
          console.log(e)
          alert('Oops! Il y a un probleme de connexion.')
        })
    }

    return {
      toggleKola,
      copytoclipboard,
      deletePost,

      post,
      loader,

      icons: {
        mdiCommentOutline,
        mdiDotsVertical,
        mdiComment,
        mdiShareVariant,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
