<template>

  <v-card :loading="loaders.loading" flat>

    <v-card-text>
      <v-row dense >
        <v-col
          v-for="(participant, index) in participantll.listEntity"
          :key="index"
          class="d-flex child-flex"
          cols="4"
        >
          <contest-participant-item
            :user="user"
            :participant="participant"
          ></contest-participant-item>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card v-intersect="infiniteScrolling"></v-card>

    <v-sheet v-if="!loaders.loading && participantll.listEntity.length === 0"
      elevation="12"
      max-width="600"
      rounded="lg"
      width="100%"
      class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="success"
        icon="mdi-check-circle"
        size="112"
      ></v-icon>

      <h2 class="text-h5 mb-6">You reconciled this account</h2>

      <p class="mb-4 text-medium-emphasis text-body-2">
        To see a report on this reconciliation, click <a href="#"
                                                         class="text-decoration-none text-info">View
        reconciliation report.</a>

        <br>

        Otherwise, you're done!
      </p>

      <v-divider class="mb-4"></v-divider>

      <div class="text-end">
        <v-btn
          class="text-none"
          color="success"
          rounded
          variant="flat"
          width="90"
        >
          Done
        </v-btn>
      </div>
    </v-sheet>

  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import ProfileItem from "@/components/ProfileItem.vue";
import ContestParticipantItem from "@/components/ContestParticipantItem.vue";

export default {
  components: {
    ContestParticipantItem,
    ProfileItem,
    FollowToggleComponent,
  },
  props: {
    participantoption: Object,
    idprofile: Number,
    user_id: String,
    user: Object,
  },
  setup(props) {
    const dialog = ref({
      newparticipant: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })

    const participantll = ref({})

    const loadparticipant = next => {

      loaders.value.loading = true
      loaders.value.retry = false


      Drequest.api('lazyloading.participant?dfilters=on&dsort=id desc')
        .param({
          'user.id:eq': props.idprofile,
          per_page: 12,
          next,
          user_id: props.user_id,
          ...props.participantoption,
        })
        .get(response => {
          console.log(response)
          loaders.value.loading = false
          if (next >= 2) {

            participantll.value.listEntity = [...participantll.value.listEntity, ...response.listEntity]
            participantll.value.next = response.next

          } else {
            loaders.value.loading = false
            participantll.value = response

          }
        })
        .fail(e => {
          loaders.value.loading = false
          loaders.value.retry = true
          console.log(e)
        })

    }
    loadparticipant(1)

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loaders.value.loading)

      if (loaders.value.loading) return

      console.log(participantll.value.next >= 2, participantll.value)
      // participantll.value.next >= 2 &&
      if (participantll.value.remain) loadparticipant(participantll.value.next)

    }

    return {
      infiniteScrolling,

      participantll,
      loaders,

      icons: {
        mdiClose,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4000;
}
</style>
