<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <!-- alert -->
      <v-col
        v-if="confirmPassword"
        cols="12"
      >
        <v-alert
          :color="alert.type"
          text
          class="mb-0"
        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{ alert.message }}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>
      <div class="pa-3">
        <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiKeyOutline }}
          </v-icon>
          <span class="text-break">Changer de mot de passe</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-left mx-auto">
          <!-- current password -->
          <v-text-field
            v-model="currentPassword"
            :type="isCurrentPasswordVisible ? 'text' : 'password'"
            :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
            label="Mot de passe"
            outlined
            dense
            @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
          ></v-text-field>

          <!-- new password -->
          <v-text-field
            v-model="newPassword"
            :type="isNewPasswordVisible ? 'text' : 'password'"
            :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
            label="Nouveau Mot de passe"
            outlined
            dense
            persistent-hint
            @click:append="isNewPasswordVisible = !isNewPasswordVisible"
          ></v-text-field>

          <!-- confirm password -->
          <v-text-field
            v-model="cPassword"
            :type="isCPasswordVisible ? 'text' : 'password'"
            :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
            label="Confirmer le Nouveau Mot de passe"
            outlined
            dense
            class="mt-3"
            @click:append="isCPasswordVisible = !isCPasswordVisible"
          ></v-text-field>
          <!-- action buttons -->
          <v-card-text>
            <v-btn
              color="primary"
              small
              class="me-3 float-right"
              @click="changepassword"
            >
              Mettre à jour
            </v-btn>
          </v-card-text>
        </v-card-text>
      </div>
      <!-- divider -->
      <v-divider></v-divider>
      <!-- alert -->
      <v-col
        v-if="alertEmail"
        cols="12"
      >
        <v-alert
          :color="alert.type"
          text
          class="mb-0"
        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{ alert.message }}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>
      <div class="pa-3">
        <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiEmail }}
          </v-icon>
          <span class="text-break">Changer l'Email</span>
        </v-card-title>

        <v-card-text
          v-if="!emailSent"
          class="two-factor-auth text-left mx-auto"
        >
          <!-- current email -->
          <v-text-field
            v-model="email"
            type="email"
            label="Email"
            outlined
            dense
          ></v-text-field>
          <v-btn
            color="primary"
            small
            class="me-3 float-right"
            @click="checkEmail(accountData.id)"
          >
            Mettre à jour
          </v-btn>
        </v-card-text>

        <v-card-text
          v-if="emailSent"
          class="two-factor-auth text-left mx-auto"
        >
          <!-- current email -->
          <v-text-field
            v-model="email"
            type="email"
            label="Email"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="code"
            type="text"
            label="Code d'activation"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            label="Mot de passe"
            outlined
            dense
          ></v-text-field>
          <v-btn
            color="primary"
            small
            class="me-3 float-right"
            @click="changeEmail(accountData.id)"
          >
            Envoyer
          </v-btn>
        </v-card-text>

        <!-- action buttons -->
        <v-card-text>
        </v-card-text>
      </div>
      <!-- divider -->
      <v-divider></v-divider>
      <v-col
        v-if="alertPhone"
        cols="12"
      >
        <v-alert
          :color="alert.type"
          text
          class="mb-0"
        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{ alert.message }}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>
      <div class="pa-3">
        <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiPhone }}
          </v-icon>
          <span class="text-break">Changer Numéro de téléphone</span>
        </v-card-title>

        <v-card-text
          v-if="!phoneSent"
          class="two-factor-auth text-left mx-auto"
        >
          <!-- current email -->
          <v-text-field
            v-model="phonenumber"
            type="phone"
            label="Numéro de Téléphone"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="phonecode"
            type="phone"
            label="Code"
            outlined
            dense
          ></v-text-field>
          <v-btn
            color="primary"
            small
            class="me-3 float-right"
            @click="checkPhone(accountData.id)"
          >
            Mettre à jour
          </v-btn>
        </v-card-text>

        <v-card-text
          v-if="phoneSent"
          class="two-factor-auth text-left mx-auto"
        >
          <!-- current email -->
          <v-text-field
            v-model="phonenumber"
            type="phone"
            label="Numero de Téléphone"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="code"
            type="text"
            label="Code d'activation"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            label="Mot de passe"
            outlined
            dense
          ></v-text-field>
          <v-btn
            color="primary"
            small
            class="me-3 float-right"
            @click="changeEmail(accountData.id)"
          >
            Envoyer
          </v-btn>
        </v-card-text>

        <!-- action buttons -->
        <v-card-text>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEmail,
  mdiKeyOutline,
  mdiLockOpenOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiPhone,
  mdiAccountOutline, mdiInformationOutline,
} from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {
  props: ['accountData'],
  data: () => ({
    alert: {
      type: null,
      message: null,
    },
    confirmPassword: false,
    alertEmail: false,
    alertPhone: false,
    emailSent: false,
    phoneSent: false,
    email: '',
    phonenumber: '',
    phonecode: '',
    password: '',
    code: '',
    isCurrentPasswordVisible: false,
    isNewPasswordVisible: false,
    isCPasswordVisible: false,
    currentPassword: '',
    newPassword: '',
    cPassword: '',
    icons: {
      mdiPhone,
      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
      mdiEmail,
      mdiAccountOutline,
      mdiInformationOutline,
    },
  }),
  methods: {
    changeEmail(id) {
      Drequest
        .api(`user.changeemail?user_id=${id}`)
        .toFormdata({
          email: this.email,
          activationcode: this.code,
          password: this.password,
        })
        .post(response => {
          this.alertEmail = true
          if (response.success) {
            this.alert.type = 'success'
            this.alert.message = response.detail
          } else {
            this.alert.type = 'error'
            this.alert.message = response.detail
          }
        })
    },
    checkEmail(id) {
      Drequest
        .api(`user.checkmail?user_id=${id}`)
        .toFormdata({
          email: this.email,
        })
        .post(response => {
          this.alertEmail = true
          if (response.success) {
            this.emailSent = true
            this.alert.type = 'success'
            this.alert.message = response.detail
          } else {
            this.alert.type = 'error'
            this.alert.message = response.detail
          }
        })
    },
    changePhone(id) {
      Drequest
        .api(`user.changetelephone?user_id=${id}`)
        .toFormdata({
          phonenumber: this.phonenumber,
          activationcode: this.code,
          password: this.password,
        })
        .post(response => {
          this.alertPhone = true
          if (response.success) {
            this.alert.type = 'success'
            this.alert.message = response.detail
          } else {
            this.alert.type = 'error'
            this.alert.message = response.detail
          }
        })
    },
    checkPhone(id) {
      Drequest
        .api(`user.checkphonenumber?user_id=${id}`)
        .toFormdata({
          phonenumber: this.phonenumber,
          phonecode: this.phonecode,
        })
        .post(response => {
          this.alertPhone = true
          if (response.success) {
            this.alert.type = 'success'
            this.alert.message = response.detail
          } else {
            this.alert.type = 'error'
            this.alert.message = response.detail
          }
        })
    },
    changepassword() {
      Drequest
        .api('user.changepassword')
        .toFormdata({
          currentpassword: this.currentPassword,
          newpassword: this.newPassword,
          confirmpassword: this.cPassword,
        })
        .post(response => {
          this.confirmPassword = true
          if (response.success) {
            this.alert.type = 'success'
            this.alert.message = response.detail
          } else {
            this.alert.type = 'error'
            this.alert.message = response.detail
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
