<template>
  <div class="">

    <div class="">
      <v-card class="">
        <template v-if="user_id">
          <v-card-text>
            <v-row>
              <v-col lg="3" md="3" class="text-center">
                <user-avatar :user="$store.state.user" @changeAvatar="()=> changeavatar = true"></user-avatar>
              </v-col>
              <v-col lg="9" md="9">
                <h3>
                  <router-link to="/pages/account-settings">
                    {{ user.username }}
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </router-link>
                </h3>
                <i>{{ user.email }}/ {{ user.phonenumber }}</i>
                <v-alert color="info" v-if="!user.is_activated">
                  Activer votre compte pour debloquer vos 600 credits bonus de bienvenu
                  <v-btn
                    v-if="!user.is_activated"
                    color="primary"
                    @click="confirm_account = true"
                  >
                    <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                    Activer mon compte
                  </v-btn>
                </v-alert>
                <v-btn block color="primary" to="/pages/account-settings">
                  <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon> Modifier mes informations </v-btn>
                <v-btn class="mt-6" block   @click="$store.dispatch('logout')">
                   Deconnexion </v-btn>
              </v-col>
            </v-row>
            <v-list
              nav
              dense
            >
              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  to="/wallet"
                >
                  <v-list-item-icon>
                    <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Mon Portefeuille</v-list-item-title>
                    <v-list-item-subtitle>{{ user.wallet.amount }} Credits
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
          </v-card-text>


            <v-list
              nav
              dense
            >
              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  to="/wallet"
                >
                  <v-list-item-icon>
                    <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Mon Portefeuille</v-list-item-title>
                    <v-list-item-subtitle>{{ user.wallet.amount }} Credits
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  to="/favoris"
                >
                  <v-list-item-icon>
                    <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Mes favoris</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item to="/subscription">
                  <v-list-item-icon>
                    <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Abonnement</v-list-item-title>
                    <v-list-item-subtitle v-if="user.subscription">
                      Un abonnement en cours
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="user.subscription">
                  <v-list-item-icon>
                    <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Credis bonus</v-list-item-title>
                    <v-list-item-subtitle>{{ subscription.credit }} Credits
                    </v-list-item-subtitle>
                    <v-list-item-action-text>
                      Du {{ subscription.date_from }} au
                      {{ subscription.date_to }}
                    </v-list-item-action-text>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
            <h3>Configurations</h3>
            <v-list
              nav
              dense
            >
              <v-list-item-group title="Configuration">
                <v-list-item
                  v-for="config in configurations"
                  :key="'conf-'+config.id"
                >
                  <v-list-item-icon>
                    <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ config.name['fr'] }}</v-list-item-title>
                    <v-list-item-content>{{ config.content['fr'] }}</v-list-item-content>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox
                      v-model="user.config[config.slug]"
                      hide-details
                      class="me-3 mt-1"
                    >
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>

          <v-card-actions  >
            <v-btn
              :loading="loaders.config"
              color="primary"
              @click="configure"
            >
              Enregistrer les
              configurations
            </v-btn>
          </v-card-actions>


        </template>
        <!-- login form -->
        <v-card-text v-else>
          <v-list>
            <v-list-item @click="authentication = true; newaccount = false;">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Me connecter
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>{{ icons.mdiArrowRight }}</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="authentication = true; newaccount = true;">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Créer mon compte
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>{{ icons.mdiArrowRight }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <!--        <v-alert
                  color="warning"
                  prominent
                  :icon="icons.mdiVuetify"
                >
                  <v-row align="center">
                    <v-col class="">
                      Installer <b>3ag Reader</b> pour plus de convivialite
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        color="primary"
                        class="mr-3"
                        @click="installPWA"
                      >
                        Installer l'application
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>-->
      </v-card>
    </div>

    <v-dialog
      v-model="authentication"
      max-width="600"
      fullscreen
    >
      <!--      <v-card-title><v-spacer></v-spacer>
              <v-btn icon @click="authentication = false; newaccount = false"><v-icon>{{icons.mdiClose}}</v-icon></v-btn>
            </v-card-title>-->
      <login-component
        v-if="!newaccount"
        :newaccount="false"
        @logged="logged"
      ></login-component>
      <v-card
        v-if="newaccount"
        elevation="0"
      >
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <span
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              3ag reader
            </h2>
          </span>
        </v-card-title>
        <v-card-text>
          <register-component
            @back="()=> newaccount = false"
            @logged="logged"
          ></register-component>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="user_id"
      v-model="confirm_account"
      max-width="400"
    >
      <validate-account-component
        :user="$store.state.user"
        @logged="logged"
        @close="confirm_account = false"
      >
      </validate-account-component>
    </v-dialog>

    <v-dialog
      v-if="user_id"
      v-model="changeavatar"
      max-width="500"
    >

      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" icon @click="changeavatar = false"><v-icon>{{icons.mdiClose}}</v-icon>
        </v-btn>
      </v-card-title>
      <profile-avatar-component
        :user="user"
      ></profile-avatar-component>
    </v-dialog>
    <v-dialog
      v-model="dialog.newpost"
      max-width="600px"
    >
      <post-form-component
        v-model="dialog.newpost"
        :postedit="postdetail"
        @posted="posted"
        @updated="updated"
        @close="()=>{dialog.newpost = false; postdetail = {}}"
      >
      </post-form-component>
    </v-dialog>

    <v-dialog v-model="dialog.imageviewer">
      <post-image-viewer-component
        :post="postdetail"
        @close="()=>{postdetail = {}; dialog.imageviewer = false}"
      ></post-image-viewer-component>
    </v-dialog>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import LoginComponent from '@/components/LoginComponent'
import { Drequest } from '@/plugins/Drequest'
import ValidateAccountComponent from '@/components/ValidateAccountComponent'
import RegisterComponent from '@/components/RegisterComponent'
import PostMinifiedComponent from '@/components/PostMinifiedComponent'
import PostFormComponent from '@/components/PostFormComponent'
import ProfileAvatarComponent from "@/components/ProfileAvatarComponent.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
    ProfileAvatarComponent,
    PostFormComponent,
    PostMinifiedComponent,
    RegisterComponent,
    ValidateAccountComponent,
    LoginComponent,
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },
  methods: {
    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  },
  setup() {
    const dialog = ref({
      newpost: false,
      imageviewer: false,
      authentication: false,
    })
    const user_id = ref(localStorage.getItem('user_id'))
    const user = ref(Drequest.getUser())
    const subscription = ref({})
    const tab = ref('papers')
    const avatars = ref([
      {
        name: '3AG',
        path: 'logo.png',
      },
      {
        name: 'Postknight',
        path: 'aya-1.png',
      },
      {
        name: 'idaho',
        path: 'idaho.jpg',
      },
      {
        name: 'idaho',
        path: 'noah.jpg',
      },
      {
        name: 'idaho',
        path: 'tew-1.jpg',
      },
      {
        name: 'idaho',
        path: 'tew-2.png',
      },
      {
        name: 'idaho',
        path: 'tew-3.jpg',
      },
      {
        name: 'bakari shibi',
        path: 'me-1.jpg',
      },
      {
        name: 'moda',
        path: 'me-2.jpg',
      },
      {
        name: 'moda',
        path: 'me-3.jpg',
      },
      {
        name: 'moda',
        path: 'me-4.jpg',
      },
      {
        name: 'yuri hello',
        path: 'tew-4.jpg',
      },
      {
        name: 'willy dark',
        path: 'tew-5.jpg',
      },
      {
        name: 'Carla meguim',
        path: 'hk-1.jpg',
      },
      {
        name: 'Samael',
        path: 'tlk-1.jpg',
      },
      {
        name: 'Lune',
        path: 'tlk-2.jpg',
      },
    ])
    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })
    const configurations = ref([])
    const newaccount = ref(false)
    const changeavatar = ref(false)

    const postdetail = ref({})
    const postll = ref({})
    const postoption = ref({ 'status._key:eq': 'published' })

    const loadPost = next => {

      loaders.value.loading = true
      loaders.value.retry = false
      Drequest.api('lazyloading.post?dfilters=on&dsort=id desc')
        .param({
          'user.id:eq': user_id.value,
          per_page: 12,
          next,
          user_id: user_id.value,
          ...postoption.value,
        })
        .get(response => {
          console.log(response)
          loaders.value.loading = false
          if (next >= 2) {
            postll.value.listEntity = [...postll.value.listEntity, ...response.listEntity]
            postll.value.next = response.next
          } else {
            postll.value = response
          }
        })
        .fail(e => {
          loaders.value.loading = false
          loaders.value.retry = true
          console.log(e)
        })

    }

    if (user_id.value) {
      Drequest.api(`detail.subscription?id=${user.value.subscription}`)
        .get(response => {
          subscription.value = response.subscription
        })
      Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=configuration')
        .get(response => {
          configurations.value = response.listEntity
        })
      loadPost(1)
    }

    const authentication = ref(false)
    const confirm_account = ref(false)
    const email = ref('')
    const password = ref('')

    const logged = (user, message) => {
      console.log(user, message)
      window.location.reload()
    }

    const setAvatar = avatar => {
      console.log(avatar)
      user.value.avatar = avatar.path
      Drequest.setUser(user.value)

      // console.log(avatar.path)
      // return
      changeavatar.value = false
      Drequest.api(`update.user?id=${user_id.value}`)
        .data({
          user: {
            avatar: avatar.path,
          },
        })
        .raw(response => {
          console.log(response)

          // window.location.reload()
        })
    }

    const configure = () => {
      // configuration update user

      loaders.value.config = true
      Drequest.api(`update.user?id=${user_id.value}`)
        .data({ user: { config: user.value.config } })
        .raw(response => {
          loaders.value.config = false
          console.log(response)
          Drequest.setUser(user.value)
        })
    }

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loaders.value.loading)
      if (loaders.value.loading) return

      console.log(postll.value.next >= 2, postll.value)
      if (postll.value.next >= 2) loadPost(postll.value.next)
    }

    const posted = post => {
      console.log(post)
      dialog.value.newpost = false
      postll.value.listEntity.unshift(post)
    }
    let postindex = -1
    const updated = post => {
      console.log(post)
      dialog.value.newpost = false
      alert('publication mise a jour')

      // postll.value.listEntity[postindex].content = post.content
      // postll.value.listEntity[postindex].contenthtml = post.contenthtml
    }
    const editPost = (post, index) => {
      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.newpost = true
    }
    const deleted = index => {
      console.log(index)
      postll.value.listEntity.splice(index, 1)
    }

    const newpost = () => {
      if (user.id) dialog.value.newpost = true
      else dialog.value.authentication = true
    }

    return {
      posted,
      newpost,
      deleted,
      editPost,
      updated,
      logged,
      configure,
      setAvatar,
      infiniteScrolling,

      postdetail,
      postindex,
      tab,
      dialog,
      postll,
      avatars,
      changeavatar,
      newaccount,
      loaders,
      confirm_account,
      configurations,
      subscription,
      authentication,
      email,
      password,
      user,
      user_id,

      icons: {
        mdiClose,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}
.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4000;
}
</style>
