<template>
  <v-btn
    v-if="fab"
    rounded small
    :loading="loader"
    class=" mr-3 "
    @click="toggleFavorite"
  >
    <span class="mr-3">{{ nbkola }}</span>
    <v-icon
      v-if="chapter.isfavorite"
      color="primary"
    >
      {{ icons.mdiHeart }}
    </v-icon>
    <v-icon
      v-else
      color="grey lighten-1"
    >
      {{ icons.mdiHeartOutline }}
    </v-icon>
  </v-btn>
  <v-btn
    v-else
    :loading="loader"
    elevation="0"
    rounded
    @click="toggleFavorite"
  >
    <span class="mr-3">{{ nbkola }}</span>
    <v-icon
      v-if="chapter.isfavorite"
      color="primary"
    >
      {{ icons.mdiHeart }}
    </v-icon>
    <v-icon
      v-else
      color="grey lighten-1"
    >
      {{ icons.mdiHeartOutline }}
    </v-icon>
  </v-btn>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiHeart,
  mdiHeartOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'

export default {
  props: {
    chapter: Object,
    fab: {
      type: Boolean,
      default: false,
    },
    nbkola: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const items = ref([])
    const loader = ref(false)
    const toggleFavorite = () => {
      const user_id = localStorage.getItem('user_id')
      if (!user_id) {
        emit('login', 1)

        return
      }
      let url = 'chapter.addfavorite'
      if (props.chapter.isfavorite) {
        url = 'chapter.removefavorite'
      }

      loader.value = true
      Drequest.api(url)
        .toFormdata({
          user_id: localStorage.getItem('user_id'),
          chapter_id: props.chapter.id,
        })
        .post(response => {
          console.log(response)

          loader.value = false
          emit('updateNotify', response)
        })
    }

    return {
      toggleFavorite,

      items,
      loader,

      icons: {
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
