<script  >


import PostReplyFormComponent from "@/components/PostReplyFormComponent.vue";
import PostComponent from "@/components/PostComponent.vue";
import {
  mdiBookmark, mdiClose,
  mdiDotsVertical, mdiEyeOffOutline,
  mdiEyeOutline,
  mdiHeart,
  mdiPlus,
  mdiExclamation,
  mdiShareVariant
} from "@mdi/js";
import {Drequest} from "@/plugins/Drequest";
import {ref} from "@vue/composition-api";
import CommentComponent from "@/components/CommentComponent.vue";

export default {
  components: {CommentComponent, PostReplyFormComponent, PostComponent},
  props:{
    comicbook:Object,
      user:Object,
      parent_id:String|Number,
  },
  setup(props){

    const postdetail = ref({ });
    const postll = ref({next:1, listEntity:[]});
    const dialog = ref({
      newpost: false,
      replypost: false,
      imageviewer: false,
      authentication: false,
      commentpost: false,
      postkola: false,
    })
    const loader = ref({
      loading: false,
      retry: false,
      options: {},
    })
    const init = (next) => {

      if (loader.value.loading) {
        console.log('is loading')
        return
      }
      loader.value.loading = true
      loader.value.retry = false
      Drequest.api('lazyloading.post?dfilters=on&dsort=id desc')
        .param({
          per_page: 9,
          next,
          ...loader.value.options,
          user_id: props.user.id,
          'parent_id:eq': props.parent_id,
           ...(props.comicbook.user_id != props.user.id ?
             { 'status._key:neq': 'draft', } : { })
        })
        .get(response => {
          // console.log(response)
          loader.value.loading = false
          if (next >= 2) {
            postll.value.listEntity = [...postll.value.listEntity, ...response.listEntity]
            postll.value.next = response.next

          } else {
            postll.value = response
          }

        })
        .fail(e => {
          loader.value.loading = false
          loader.value.retry = true
          console.log(e)
        })
    }
    init(1)

    const posted = post => {
      console.log(post)
      dialog.value.newpost = false
      dialog.value.replypost = false
      postll.value.listEntity.unshift(post)

      // Drequest.__ads.css('display', 'block')

    }
    let postindex = -1
    const updated = post => {
      console.log(post)
      dialog.value.newpost = false
      alert('publication mise a jour')

      // postll.value.listEntity[postindex].content = post.content
      // postll.value.listEntity[postindex].contenthtml = post.contenthtml
    }
    const editPost = (post, index) => {

      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.newpost = true

      // Drequest.__ads.css('display', 'none')

    }
    const replyPost = (post, index) => {

      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.replypost = true
    }
    const commentPost = (post, index) => {

      dialog.value.newpost = false
      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.commentpost = true
    }
    const postKola = (post, index) => {

      console.log(post)
      postdetail.value = post
      postindex = index
      dialog.value.postkola = true
    }
    const deleted = index => {
      console.log(index)
      postll.value.listEntity.splice(index, 1)
    }

    return {
      init,
      posted,
      updated,
      //infiniteScrolling,
      editPost,
      replyPost,
      commentPost,
      postKola,
      deleted,

      postdetail,
      dialog,
      postll,
      loader,

      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiBookmark,
        mdiShareVariant,
        mdiHeart,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiClose,
        mdiExclamation,
      },
    }

  }
}
</script>

<template>
<div>

  <post-reply-form-component v-if="comicbook.user_id == user.id"
    :postedit="{ }"
    :mainpost="{id:parent_id}"
    @posted="posted"
    @updated="updated"
  ></post-reply-form-component>

  <post-component class=" social-post"
                  v-for="(post, $index) in postll.listEntity"
                  :key="post.id"
                  :index="$index"
                  :post-item="post"
                  :user="user"
                  :comicbook="comicbook"
                  :limit="3"
                  @login="()=>$store.commit('updateAuthentication', true)"
                  @editPost="(post, index)=>editPost(post, index)"
                  @commentPost="commentPost"
                  @postKola="postKola"
                  @deleted="(index)=>deleted(index)"
                  @initlogin="()=>{$store.commit('updateAuthentication', true)}"
  ></post-component>
<!--
  <v-card v-intersect="infiniteScrolling"></v-card>-->

  <v-card-actions
    v-if="loader.retry"
    class="text-center"
  >
    <v-btn
      class="ma-auto"
      color="primary"
      @click="init(postll.next)"
    >
      Recharger
    </v-btn>
  </v-card-actions>
  <v-sheet
    v-if="loader.loading"
    :color="`grey darken-2 `"
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      max-width="500"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
  <v-sheet v-else-if="!loader.loading && !loader.retry && postll.listEntity.length === 0"
           elevation="0"
           max-width="600"
           rounded="lg"
           width="100%"
           class="pa-4 text-center mx-auto"
  >
    <v-icon
      class="mb-5"
      color="warning"
      size="112"
    >{{ icons.mdiExclamation }}
    </v-icon>

    <p class="  mb-6">Aucun element trouve</p>

  </v-sheet>

  <v-bottom-sheet style="z-index: 4100"
                  v-model="dialog.commentpost"
                  max-width="600"
                  class="pb-12"
  >
    <v-card >
      <v-card-title>
        Commentaires
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog.commentpost = false"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <comment-component
        :key="postdetail.id"
        :post_id="postdetail.id"
        :quick_comment="false"
        :limit="12"
        :user="user"
        @initlogin="()=>{dialog.authentication = true}"
      ></comment-component>
    </v-card>
  </v-bottom-sheet>

</div>
</template>

<style scoped lang="scss">

</style>
