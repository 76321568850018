<template>
  <div class="">
    <div class="">
      <v-card class="">
        <!-- logo -->
        <v-card-title class="d-flex py-7 position-relative">
          <h2 class="text-2xl font-weight-semibold">
            Portfeuille : {{ user.wallet.amount }} Credits
          </h2>
          <v-spacer>
          </v-spacer>
          <v-btn
            absolute
            rounded
            right
            color="primary"
            @click="dialog.newrefill = true"
          >
            Acheter des credits
          </v-btn>
        </v-card-title>

        <!-- login form -->
        <v-card-text>
          <h6 class="text-h6">
            Historique des transactions
          </h6>
          <v-divider></v-divider>
          <v-progress-circular
            v-if="!wtransaction.listEntity.length"
            class="ma-auto"
            :size="30"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-list
            nav
            dense
          >
            <v-list-item-group
              color="primary"
            >
              <v-list-item
                v-for="item in wtransaction.listEntity"
                :key="item.id"
              >
                <v-list-item-icon>
                  <v-chip
                    v-if="item.type == 'debit'"
                    color="error"
                  >
                    <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
                  </v-chip>
                  <v-chip
                    v-else
                    color="success"
                  >
                    <v-icon>{{ icons.mdiCreditCardOutline }}</v-icon>
                  </v-chip>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.amount }} Credits</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.comment }} - {{
                      item.created_at
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-action-text v-if="item.reference">
                    Ref. {{
                      item.reference
                    }}
                  </v-list-item-action-text>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog.newrefill"
      max-width="500"
    >
      <v-card elevation="0">
        <v-card-title>
          Recharger mon compte
          <v-spacer></v-spacer>
          <v-btn
            icon
            text
            @click="dialog.newrefill = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <wallet-refill-component
          :user="user"
          @refilled="refilled"
        ></wallet-refill-component>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.snack"
      max-width="600"
    >
      <v-card
        color="success"
        elevation="0"
      >
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            text
            @click="dialog.snack = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>Votre recharge a bien été effectuée. Merci</v-card-title>
        <v-card-text>Vous pouvez souscrire à un abonnement pour avoir du crédit bonus</v-card-text>
        <v-card-actions>
          <v-btn
            small
            text
            @click="dialog.snack = false"
          >
            Terminer
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn to="/subscription">
            Souscrire à un abonnement
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import WalletRefillComponent from '@/components/WalletRefillComponent'

export default {
  components: { WalletRefillComponent },
  setup() {
    const user = ref(Drequest.getUser())
    const wtransaction = ref({ listEntity: [] })
    const dialog = ref({
      newrefill: false,
      snack: false,
    })

    const init = () => {
      Drequest.getWalletTransaction(user.value)
        .get(response => {
          wtransaction.value = response
        })
    }
    init()

    const refilled = () => {
      dialog.value.newrefill = false
      dialog.value.snack = true
      Drequest.updateUserSession(user.value)
      user.value = Drequest.getUser()
      init()
    }

    return {
      init,
      refilled,

      wtransaction,
      user,
      dialog,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
