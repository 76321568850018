<script >

import {
  mdiAccountGroupOutline,
  mdiCartOutline,
  mdiClose, mdiCommentAlertOutline,
  mdiEyeOffOutline,
  mdiEyeOutline, mdiImageAlbum, mdiStarOutline
} from "@mdi/js";

export default {
  props:{
    index:Number,
    item:Object,
  },
  data(){
    return {

      icons: {
        mdiCartOutline,
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiAccountGroupOutline,
        mdiCommentAlertOutline,
        mdiStarOutline,
        mdiImageAlbum,
      },
    }
}
}
</script>

<template>

  <v-card
          :style="` background: url('${item.banner}')) center center / cover no-repeat`"
          class="mb-3 overflow-hidden " >

    <v-img height="100%" width="100%" cover :src="item.banner" style="position: absolute; /*filter:blur(1px)*/"  ></v-img>

    <v-list-item class="py-6"
                 :key="item.name"
                 :to="'/comicbook/'+item.nameseo+'/'+item.id"
    >
      <h3 ><v-chip color="black" >#{{index+1}}</v-chip></h3>
      <v-avatar
        class="ml-3 mr-3" >
        <v-img
          max-width="50"
          :src="item.cover_src"
          :lazy-src="require('@/assets/images/holder.jpg')"></v-img>
      </v-avatar>

      <v-list-item-content>
        <v-list-item-title class="title-inline">
          <v-chip color="" text-color="white" class="alert" ><h3>{{ item.name }}</h3></v-chip>
          <v-spacer></v-spacer>
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-chip v-if="item.nbviews" color="warning" small>
            <v-icon  small>
              {{ icons.mdiEyeOutline }}
            </v-icon> {{ item.nbviews }}
          </v-chip>
          <template v-else-if="item.rate" >
          <v-chip  color="warning" small>
            <v-icon  small>
              {{ icons.mdiStarOutline }}
            </v-icon> {{ item.rating }}
          </v-chip>
          {{ item.nbvote }} votes
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
      </v-list-item-action>

    </v-list-item>


  </v-card>

</template>

<style scoped lang="scss">

</style>
