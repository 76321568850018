<template>
  <div class=" pt-3">
    <v-card
      class="mb-3 pb-3 mx-auto" elevation="0"
      max-width="600"
    >
      <v-alert
        variant="tonal"
        v-if="post.parent_id" class="pb-1">

        <router-link class=" text--white" :to="'/activity/'+post.parent_id">
          <v-icon v-if="post.parent_category.slug == 4" size="20">{{
              icons.mdiFolderAlert
            }}
          </v-icon>
          <v-icon v-if="post.parent_category.slug == 5" size="20">
            {{ icons.mdiAccountGroupOutline }}
          </v-icon>
          <b class="ml-3" v-if="post.parent_category.name">{{ post.parent_category.name.fr }}</b>
        </router-link>
        .
        <router-link class=" text--white" :to="'/activity/'+post.parent_id"><span class="ml-3">{{
            post.parent_title
          }}</span>
        </router-link>

      </v-alert>

      <v-card-actions class="pa-1">
        <v-list-item
          class="grow pa-1"
          dense
        >
          <v-list-item-avatar color="grey darken-3">
            <v-img
              v-if="post.user.avatar"
              contain
              class="elevation-6"
              alt=""
              :src="require('@/assets/images/avatars/'+post.user.avatar)"
            ></v-img>
            <v-img
              v-else
              contain
              class="elevation-6"
              alt=""
              :src="require('@/assets/images/avatars/logo.png')"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="">
              <router-link :to="'/profile/'+post.user.id"><b>{{ post.user.username }}</b>
              </router-link>
            </v-list-item-title>
            <v-list-item-subtitle><duration :last-date="post.created_at"></duration></v-list-item-subtitle>
          </v-list-item-content>

          <v-row
            align="center"
            justify="end"
          >

            <template v-if="user.id != post.user.id">
              <follow-toggle-component
                v-if="post.user.following !== 1"
                :status="post.user.following"
                :user_id="parseInt(post.user.id)"
                :current_user_id="parseInt(user.id)"
                @initlogin="()=>$emit('initlogin')"
                @following="following"
              ></follow-toggle-component>

            </template>
            <!--            <v-btn v-if="post.postimages.length > 1"
                               class="mr-3"
                               icon title="Ouvrir avec le lecteur d'image"
                               @click="$emit('imageViewer', post)"
                        >
                          <v-icon class="mr-1">
                            {{ icons.mdiCollapseAllOutline }}
                          </v-icon>
                        </v-btn>-->

            <v-menu
              bottom
              left
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-1">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item :to="'/activity/'+post.id">
                  <v-list-item-title>Aller au post</v-list-item-title>
                </v-list-item>
                <v-list-item @click="loader.dialog = true">
                  <v-list-item-title>Partager</v-list-item-title>
                </v-list-item>
                <template v-if="post.user.id == user.id">
                  <v-list-item v-if="post.category.slug == '4'"
                               @click="$emit('replyPost', post, index)"
                  >
                    <v-list-item-title> Ajouter un episode</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    color="error"
                    @click="$emit('editPost', post, index)"
                  >
                    <v-list-item-title>Modifier ma publication</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    color="error"
                    @click="deletePost"
                  >
                    <v-list-item-title>Supprimer ma publication</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-row>
        </v-list-item>
      </v-card-actions>

      <v-card-title v-if="post.title">{{ post.title }}</v-card-title>

      <template v-if="post.postimages.length > 1">
        <v-carousel
          hide-delimiters
          :continuous="false"
          show-arrows
          contain
          @change="nextslide"
        >
          <v-carousel-item
            v-for="(item,i) in post.postimages"
            :key="i"
            hide-delimiter-background
            hide-delimiters
            :delimiter-icon="icons.d"
          >
            <v-img
              :src="item.uploaddir+'700_'+item.postimage"
              contain
              min-height="400"
              :lazy-src="require('@/assets/images/holder.jpg')"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0 "
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>

      </template>
      <v-img
        v-for="postimage in post.postimages"
        :key="'pi'+postimage.id"
        v-else-if="post.postimages.length"
        :src="postimage.uploaddir+'700_'+postimage.postimage"
        :lazy-src="require('@/assets/images/holder.jpg')"
        contain
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0 "
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <div class="text-center">
        <template v-if="post.postimages.length > 1">
          <v-rating
            :empty-icon="icons.mdiCircleOutline"
            :full-icon="icons.mdiCircle"
            :half-icon="icons.mdiCircleOutline"
            hover
            :length="post.postimages.length"
            size="24"
            :value="iteration + 1"
          ></v-rating>
          <v-spacer></v-spacer>
        </template>
      </div>

      <v-card-actions v-if="post.postimages.length" class=" pb-0">
        <v-btn
          :loading="loader.like"
          icon
          @click="toggleKola"
        >
          <v-icon v-if="post.liked" color="primary">{{ icons.mdiHeart }}</v-icon>
          <v-icon v-else>{{ icons.mdiHeartOutline }}</v-icon>
        </v-btn>
        <v-chip @click="$emit('postKola', post)" v-if="post.nbkola>0">
          {{ post.nbkola }}
        </v-chip>
        <v-spacer></v-spacer>

<!--        <v-btn v-if="post.category.slug == '5'" @click="$emit('replyPost', post, index)"
               class="ml-3" color="warning" small title="Participer"
        >
          <v-icon>{{ icons.mdiArrowUpBoldOutline }}</v-icon>
        </v-btn>-->
        <v-btn text @click="$emit('commentPost', post, index)" rounded >
          <v-icon>{{ icons.mdiCommentOutline }}</v-icon>
          <span class="hidden-xs-only">Commentaires</span>
        </v-btn>
        <v-btn text @click="loader.dialog = true" class="ml-3" rounded >
          <v-icon>{{ icons.mdiShareVariantOutline }}</v-icon>
          <span class="hidden-xs-only">Partager</span>
        </v-btn>
      </v-card-actions>

      <v-card-text
        class="pb-0" v-html="post.contenthtml"
      ></v-card-text>
<!--
      <read-more
        :content="post.contenthtml" :max-length="120" ></read-more>-->

      <template  v-if="post.postimages.length == 0">
<!--        <p>{{ post.creationdate }}</p>-->
        <v-card-actions class=" pb-0">
          <v-btn
            :loading="loader.like"
            icon
            @click="toggleKola"
          >
            <v-icon v-if="post.liked" color="primary">{{ icons.mdiHeart }}</v-icon>
            <v-icon v-else>{{ icons.mdiHeartOutline }}</v-icon>
          </v-btn>
          <v-chip @click="$emit('postKola', post)" v-if="post.nbkola>0">
            {{ post.nbkola }}
          </v-chip>
          <v-spacer></v-spacer>

          <!--        <v-btn v-if="post.category.slug == '5'" @click="$emit('replyPost', post, index)"
                         class="ml-3" color="warning" small title="Participer"
                  >
                    <v-icon>{{ icons.mdiArrowUpBoldOutline }}</v-icon>
                  </v-btn>
          <v-btn @click="$emit('commentPost', post, index)" text >
            <v-icon>{{ icons.mdiCommentOutline }}</v-icon>
            <span class="hidden-xs-only">Commentaires</span>
          </v-btn> -->
          <v-btn @click="loader.dialog = true" class="ml-3"  text >
            <v-icon>{{ icons.mdiShareVariantOutline }}</v-icon>
            <span class="hidden-xs-only">Partager</span>
          </v-btn>
        </v-card-actions>
      </template>

      <template v-if="quick_comment">
        <comment-items-component
          :quick_comment="true"
          :limit="limit"
          :user="user"
          :post_id="(post.id)"
          :nbcomment="(post.nbcomment)"
          @initlogin="()=>$emit('initlogin')"
        ></comment-items-component>
        <v-card-subtitle class="mt-3 text-center" @click="$emit('commentPost', post, index)">
          <v-icon>{{ icons.mdiCommentOutline }}</v-icon>
          Laissez un commentaire
        </v-card-subtitle>
      </template>
      <comment-component v-else
                         :limit="limit"
                         :user="user"
                         :post_id="(post.id)"
                         :nbcomment="post.nbcomment"
                         @initlogin="()=>$emit('initlogin')"
      ></comment-component>


    </v-card>
    <v-dialog max-width="600" v-model="loader.dialog">
      <share-component @close="()=>loader.dialog = false" :linkshare="linkshare"></share-component>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiWhatsapp,
  mdiFacebook,
  mdiPinterest,
  mdiReddit,
  mdiFacebookMessenger,
  mdiTwitter,
  mdiTelegram,
  mdiDotsVertical,
  mdiCommentOutline,
  mdiShareVariant,
  mdiHeartOutline,
  mdiHeart,
  mdiMinus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiShareVariantOutline,
  mdiClose,
  mdiCodeTags,
  mdiContentCopy,
  mdiCircle,
  mdiCircleOutline,
  mdiCollapseAllOutline,
  mdiArrowUpBoldOutline,
  mdiArrangeSendBackward,
  mdiAccountGroupOutline,
  mdiFolderAlert,
  mdiFileImageOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'

import CommentComponent from '@/components/CommentComponent'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'
import ShareComponent from '@/components/ShareComponent'
import FollowToggleComponent from '@/components/FollowToggleComponent.vue'
import CommentItemsComponent from "@/components/CommentItemsComponent.vue";
import Duration from "@/components/Duration.vue";
import ReadMore from "@/components/ReadMore.vue";
// import { ShareFacebook, ShareTwitter } from 'vue-share-social'

export default {
  components: {
    ReadMore,
    Duration,
    CommentItemsComponent,
    FollowToggleComponent,
    ShareComponent,
    CommentComponent,
    // ShareFacebook,
    // ShareTwitter
  },
  props: {
    postItem: Object,
    user: Object,
    limit: Number,
    index: Number,
    quick_comment: {
      type: Boolean,
      default: () => true,
    },
    comicbook: {
      type: Object | null,
      default: () => null,
    },
    loadreply: {
      type: Boolean,
      default: () => false,
    },
  },
  //composition api
  setup(props, {emit}) {

    const $vuetify = getVuetify()
    const loader = ref({
      loading: true,
      like: false,
      dialog: false,
    })

    const post = ref(JSON.parse(JSON.stringify(props.postItem)))
    const linkshare = `/activity/${post.value.id}`
    const toggleKola = () => {
      if (props.user.id) {
        loader.value.like = true
        Drequest.toggleKola('post', post.value.id, post.value.liked)
          .post(response => {
            console.log(response)
            loader.value.like = false
            post.value.liked = !post.value.liked
            post.value.nbkola = response.nbkola
          })
      } else {
        alert('Vous devez etre connecte pour liker')
      }
    }

    const deletePost = () => {
      if (!confirm('Cette publication va etre supprime.\n\n Vous confirmez cette action?')) {
        return 0
      }

      Drequest.api(`post.delete?id=${post.value.id}`)
        .get(response => {
          console.log(response)
          alert(response.detail)
          emit('deleted', props.index)
        })
        .fail(e => {
          console.log(e)
          alert('Oops! Il y a un probleme de connexion.')
        })
    }

    const iteration = ref(0)
    const nextslide = (i) => {
      console.log(i)
      iteration.value = i
    }
    const following = (response) => {
      console.log(response)
      window.location.href = '/profile/' + post.value.user.id
    }
    return {
      nextslide,
      toggleKola,
      deletePost,
      following,

      post,
      linkshare,
      loader,
      iteration,

      icons: {
        mdiAccountGroupOutline,
        mdiFolderAlert,
        mdiFileImageOutline,
        mdiClose,
        mdiDotsVertical,
        mdiCommentOutline,
        mdiShareVariant,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiMinus,
        mdiShareVariantOutline,
        mdiCodeTags,
        mdiContentCopy,
        mdiCircle,
        mdiCircleOutline,
        mdiCollapseAllOutline,
        mdiWhatsapp,
        mdiFacebook,
        mdiPinterest,
        mdiReddit,
        mdiFacebookMessenger,
        mdiTwitter,
        mdiTelegram,
        mdiArrowUpBoldOutline,
        mdiArrangeSendBackward,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.share {
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px, rgb(0 0 0 / 12%) 0px 1px 14px 0px;
}

.share span {
  margin-top: 10px;
}
</style>
