<template>

  <v-app>
    <router-view></router-view>
  </v-app>

</template>

<script>
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import PWAPrompt from '@/layouts/components/PWAPrompt'
import FlatScreen from '@/layouts/components/FlatScreen'

export default {
  components: {FlatScreen, PWAPrompt},
  data: () => ({
    icons: {
      mdiFacebook,
      mdiTwitter,
      mdiGithub,
      mdiGoogle,
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
    height: window.innerHeight,
  }),

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      this.height = window.innerHeight
    },
  },

}
</script>

<style>
#bottom-navigation {
  position: fixed;
}
</style>
