<template>
  <div>
    <v-card
      class="mb-3 pb-3 mx-auto" elevation="0"
      max-width="600" >
      <v-img v-if="mainpost.id && mainpost.postimages"
        :src="mainpost.postimages[0].uploaddir+'700_'+mainpost.postimages[0].postimage"
        class="align-top mb-3"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        height="200px"
        cover
      >
        <v-card-title class="text-white"  >
          {{ mainpost.title }}
          <v-spacer></v-spacer>
          <v-btn color="primary"
            fab small
            @click="$destroy; $emit('close'); "
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
      </v-img>

      <post-form-component
        :postedit="{ statuskey:'published', }"
        :parent_id="mainpost.id"
        @posted="(post)=>$emit('posted', post)"
        @updated="(post)=>$emit('updated', post)"
        @close="()=>$emit('cancelpost')"
      >
      </post-form-component>
    </v-card>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiClose,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAccountGroupOutline,
  mdiChevronLeft,
  mdiArrowUpDown,
  mdiFileImageOutline
} from '@mdi/js'
import {
  computed, ref, onMounted, onUnmounted, watch,
} from '@vue/composition-api'
import draggable from 'vuedraggable'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import PostFormComponent from "@/components/PostFormComponent.vue";

export default {
  components: {
    PostFormComponent,
    draggable,
  },
  props: {
    mainpost: Object,
    postedit: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props, { emit }) {

    return {

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFileImageOutline,
        mdiAccountGroupOutline,
        mdiArrowUpDown,
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.todo {
  border-top: 1px solid var(--v-secondary-base);
}

.list-move {
  transition: .3s;
}
</style>
