<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text
      hidden
      class="d-none hidden"
    >
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="accountDataLocale.avatarImg"></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>

        <input
          ref="refInputEl"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
        >
          Reset
        </v-btn>
        <p class="text-sm mt-5">
          Allowed JPG, GIF or PNG. Max size of 800K
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.username"
              label="Username"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.firstname"
              label="Nom"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.lastname"
              label="Prenom"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
          <v-textarea
            v-model="accountDataLocale.bio"
            outlined
            rows="3"
            label="Bio"
          ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.cp"
              dense
              outlined
              label="Code postal"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.city"
              dense
              outlined
              label="Ville"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.address"
              dense
              outlined
              label="Adresse"
            ></v-text-field>
          </v-col>

          <!-- alert -->
          <v-col cols=" d-none 12">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Your email is not confirmed. Please check your inbox.
                  </p>
                  <a
                    href="javascript:void(0)"
                    class="text-decoration-none warning--text"
                  >
                    <span class="text-sm">Resend Confirmation</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              :loading="loaders.loadform"
              color="primary"
              class="me-3 mt-4"
              @click="save"
            >
              Enregistrer
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              :loading="loaders.loadform"
              class="mt-4"
              type="reset"
              @click.prevent="resetForm"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-snackbar
        v-model="snackbar.snack"
        :color="snackbar.color"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar.snack = false"
          >
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
  props: {
    accountData: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props) {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    const accountDataLocale = ref(JSON.parse(JSON.stringify(props.accountData)))
    const countries = ref([])
    const loaders = ref({
      loadform: false,
    })
    const snackbar = ref({
      color: 'primary',
      text: '',
      snack: false,
      endchapter: false,
      loadconfig: false,
      loader: true,
      touchless: false,
    })
    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    }
    const save = () => {
      loaders.value.loadform = true
      Drequest.api(`update.user?id=${props.accountData.id}`)
        .data({
          user: {
            username: accountDataLocale.value.username,
            firstname: accountDataLocale.value.firstname,
            lastname: accountDataLocale.value.lastname,
            city: accountDataLocale.value.city,
            address: accountDataLocale.value.address,
            cp: accountDataLocale.value.cp,
          },
        })
        .raw(response => {
          loaders.value.loadform = false
          if (response.success) {
            snackbar.value.text = ('information enregistrees avec succes!')
            snackbar.value.color = 'success'
            snackbar.value.snack = true
            let user = Drequest.getUser()
            user = {
              ...user,
              ...response.user,
            }
            Drequest.updateUserSession(user)
          } else {
            alert(response.detail)
          }
        })
        .fail(e => {
          console.log(e)
          loaders.value.loadform = false
          alert('Oops! Il y a une soucis de connexion internet!')
        })
    }

    Drequest.api('lazyloading.country?dfilters=on&next=1&per_page=1000')
      .get(response => {
        countries.value = response.listEntity
      })

    return {
      loaders,
      countries,
      status,
      accountDataLocale,
      snackbar,

      save,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
