<script >

import CommentItemComponent from "@/components/CommentItemComponent.vue";
import {mdiExclamation, mdiEyeOffOutline, mdiEyeOutline, mdiInformationOutline} from "@mdi/js";
import {Drequest} from "@/plugins/Drequest";
import {ref} from "@vue/composition-api";

export default {
  components: {CommentItemComponent},
  props:{
    url:String,
    user:Object,
    comicbook:{
      type:Object,
      default:()=>{}
    },
    chapter:{
      type:Object,
      default:()=>{}
    }
  },
  setup(props){

    const loader = ref({
      detail: true,
      loading: true,
    })
    const lastcomments = ref([])
    loader.value.loading = true

    let url = '';
    if (props.user.id) {
      url = `&user_id=${props.user.id}`
    }
    Drequest.api(`postcomment.${props.url}${url}`)
      .param({
      })
      .get(response => {
        console.log(response)
        loader.value.loading = false
        lastcomments.value = response.lastcomments
      })

    return {
      loader,
      lastcomments,

      icons: {
        mdiExclamation,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiInformationOutline,
      },
    }
  }
}
</script>

<template>

  <v-card elevation="0" class="mb-6">
    <v-card-title>
      Derniers Commentaires
      <v-spacer></v-spacer>

    </v-card-title>
    <template  v-if="loader.loading"  >
      <v-card elevation="0" v-for="n in 3"
              :color="`grey darken-2 `"
              :key="n"
      >
        <v-skeleton-loader
          class="mx-auto"
          :height="60"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
      </v-card>
    </template>
    <v-sheet v-else-if="!loader.loading && lastcomments.length === 0"
             elevation="0"
             max-width="600"
             rounded="lg"
             width="100%"
             class="pa-4 text-center mx-auto"
    >
      <v-icon
        class="mb-5"
        color="warning"
        size="112"
      >{{ icons.mdiExclamation }}
      </v-icon>

      <p class="  mb-6">Aucun element trouve</p>

    </v-sheet>
    <template v-else >
      <v-list  class="pb-12 px-lg-3 px-md-3" dense>
        <template v-for="(item, index) in lastcomments">
          <v-divider
            :key="index"
          ></v-divider>
          <comment-item-component
            :key="item.id"
            :index="index"
            :item="item"
            :user="user"
          ></comment-item-component>
          <!--                <div class="text-right">
                          <v-chip small class="mb-3"
                                  :to="'/scan/'+comicbook.nameseo+'/'+item.c_id" color="warning"> Lire le chapitre </v-chip>
                          </div>-->
        </template>
      </v-list>
    </template>
  </v-card>
</template>

<style scoped lang="scss">

</style>
