<template>
  <div class="">

    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          @click="e1 = 1"
        >
          Devenir Createur
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="2"
          @click="e1 = 2"
        >
          Creer mon compte
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          step="3"
          @click="e1 = 3"
        >
          Completer mon compte
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card-text>
            ce quil faut savoir pour devenir createur.
          </v-card-text>
        </v-stepper-content>
        <v-stepper-content step="2">
        </v-stepper-content>
        <v-stepper-content step="3">

          <v-card
            elevation="0"
            class="auth-card"
          >
            <!-- login form -->
            <v-form
              class="mt-3"
            >
              <v-text-field
                v-model="user.username"
                outlined
                dense
                label="Nom d'artiste"
                hide-details
                required
                :rules="[validators.required]"
                class="mb-3"
              ></v-text-field>

              <v-text-field
                v-model="user.firstname"
                outlined
                dense
                label="Nom "
                hide-details
                required
                :rules="[validators.required]"
                class="mb-3"
              ></v-text-field>

              <v-text-field
                v-model="user.lastname"
                outlined
                dense
                label="prenom"
                hide-details
                required
                :rules="[validators.required]"
                class="mb-3"
              ></v-text-field>

              <v-btn
                :loading="server_response.loadregistration"
                block
                color="primary"
                class="mt-6"
                @click="register"
              >
                Creer mon compte
              </v-btn>
            </v-form>

            <v-dialog
              v-model="server_response.registration_completed"
              persistent
              max-width="420"
            >
              <v-card class="text-center">
                <v-img
                  :src="require('@/assets/images/pages/welcome.png')"
                  max-height="210px"
                  max-width="300px"
                  alt="welcome"
                  contain
                  class="me-3 "
                ></v-img>

                <v-card-actions>
                  <v-btn
                    text
                    @click=" window.location.reload()"
                  >
                    Fermer
                  </v-btn>

                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    to="/subscription/new"
                  >
                    souscrire à un abonnement
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {computed, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {getVuetify} from '@/plugins/utils'
import Banner from '@/components/Banner'
import {useRouter} from "@/utils";
import FollowToggleComponent from "@/components/FollowToggleComponent.vue";
import ProfileItem from "@/components/ProfileItem.vue";
import {emailValidator, lengthValidator, required, selected} from "@/plugins/utils/validation";
// import { useMeta } from 'vue-meta'

export default {
  components: {ProfileItem, FollowToggleComponent, Banner},
  setup() {
    //useMeta({ title: 'Some Page' })
    const $vuetify = getVuetify()
    const loader = ref({
      catalog: true,
      reload: false,
      followed: false,
    })
    const items = ref([])
    const drawer = ref(false)
    const e1 = ref(1)
    const {router} = useRouter()

    const route = router.currentRoute
    const form = ref(null)
    const user = ref(Drequest.getUser())

    const validate = () => {
      form.value.validate()
    }
    const register = () => {
      form.value.validate()
    }
    const server_response = ref({
      error: {},
      error_message: '',
      snack: false,
      alert: false,
      loadactivation: false,
      loadregistration: false,
    })
    return {
      register,
      validate,

      user,
      loader,
      e1,
      items,
      form,
      drawer,
      server_response,

      validators: {
        required,
        emailValidator,
        lengthValidator,
        selected,
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
