<template>
  <v-container class="pa-0 pb-0">
    <template v-if="postcommentll.listEntity" >
      <p v-if="postcommentll.nb_element > 3 && quick_comment">
        Lire les {{ postcommentll.nb_element }}
        commentaires
      </p>
      <v-list dense>
        <template v-for="(item, index) in postcommentll.listEntity">
          <v-divider
            :key="index"
          ></v-divider>

          <v-list-item dense
                       :key="item.id" class="align-start"
          >
            <v-list-item-avatar>
              <v-img
                v-if="item.user.avatar"
                contain
                :src="require('@/assets/images/avatars/'+item.user.avatar)"
              ></v-img>
              <v-img
                v-else
                contain
                :src="require('@/assets/images/avatars/logo.png')"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  :to="'/profile/'+item.user.id"> {{ item.user.username }}</router-link>
              </v-list-item-title>
              <p class="text-sm" v-html="item.comment"></p>
              <v-list-item-action-text>
                <small class="mr-3 v-size--small"><duration :last-date="item.created_at"></duration></small>
                <v-btn @click="postcomment = item" x-small link text>- repondre</v-btn>
                <small class="float-right v-size--x-small">
                  <kola-toggle-component
                    class="mr-3"
                    :subject="'postcomment'"
                    :identify="item.id"
                    :status="item.liked"
                    :user_id="user.id"
                    :counter="item.nbkola"
                    @liked="(liked, nbkola)=>{item.liked = liked; item.nbkola = nbkola}"
                  ></kola-toggle-component>
                  <v-menu
                    v-if="item.user.id == user.id"
                    top
                    left
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        small
                        v-on="on"
                      >
                        <v-icon class="mr-1">
                          {{ icons.mdiDotsHorizontal }}
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <template>
                        <v-list-item
                          color="error"
                          @click="deleteComment(item, index)"
                        >
                          <v-list-item-title>Supprimer</v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </small>
              </v-list-item-action-text>

              <span class="text-xs"
                    v-if="item.nbreply > 0"
                    @click=" loadreply = true"
              >--- Afficher les reponses {{ item.nbreply }}</span>

            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.nbreply && loadreply"
                       :key="'r-'+item.id"
          >
            <v-list-item-avatar></v-list-item-avatar>
            <v-list-item-content>
              <comment-response-list-component
                :user="user"
                :limit="limit"
                :parent_id="item.id"
                :newcomment="commentresponse"
                :nbreply="item.nbreply"
                @reply="(item)=>postcomment = item"
              ></comment-response-list-component>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-btn v-if="postcommentll.remain >= 1" @click="showMore()">
        Voir plus de commentaires
      </v-btn>
    </template>
  </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiCommentAlertOutline,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import CommentFormComponent from '@/components/CommentFormComponent'
import CommentResponseListComponent from '@/components/CommentResponseListComponent'
import KolaToggleComponent from '@/components/KolaToggleComponent'
import Duration from "@/components/Duration.vue";

export default {
  components: {
    Duration,
    KolaToggleComponent,
    CommentResponseListComponent,
    CommentFormComponent
  },
  props: {
    post_id: Number|String,
    quick_comment: Boolean,
    limit: {
      type: Number|String,
      default: () => 6,
    },
    nbcomment: {
      type: Number|String,
      default: () => 1,
    },
    user: {
      type: Object,
      default: () => {

      },
    },
    loadreply: {
      type: Boolean|Number,
      default: () => false,
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const items = ref([])
    const postcommentll = ref({ listEntity: [] })
    const postcomment = ref({})
    const commentresponse = ref({})
    const init = (next) => {
      Drequest.api(`lazyloading.postcomment?dfilters=on&post.id:eq=${props.post_id}`)
        .param({
          next: next,
          'parent_id:isNull': 1,
          'user_id': props.user.id,
          per_page: props.limit,
        })
        .get(response => {
          console.log(response)

          if (next >= 2) {
            postcommentll.value.listEntity = [...postcommentll.value.listEntity, ...response.listEntity]
            postcommentll.value.next = response.next
            postcommentll.value.remain = response.remain
          } else
            postcommentll.value = response
        })
    }

    if (props.nbcomment > 0) {
      init(1)
    }

    const showMore = ()=>{
      init(postcommentll.value.next)
    }

    const newcomment = response => {
      console.log(response)
      if (!response.postcomment.parent_id) {
        postcommentll.value.listEntity.push(response.postcomment)
      } else {
        postcomment.value.nbreply += 1
        commentresponse.value = response.postcomment
      }
    }
    const deleteComment = (item, index) => {
      if (!confirm('Ce commentaire va etre supprime.\n\n Vous confirmez cette action?')) {
        return 0
      }

      Drequest.api(`delete.postcomment?id=${item.id}`)
        .get(response => {
          console.log(response)
          postcommentll.value.listEntity.splice(index, 1)
        })
    }

    return {
      newcomment,
      deleteComment,

      items,
      postcomment,
      postcommentll,
      commentresponse,
      showMore,

      icons: {
        mdiDotsHorizontal,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiDotsVertical,
        mdiCommentAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

/*.comment-form {
  position: absolute;
  bottom: 0;
  background: white;
}*/
</style>
