/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { Drequest } from '@/plugins/Drequest'

if (process.env.NODE_ENV === 'production') {

  async function getPublicKey() {
    const { key } = await fetch(Drequest.__env + 'api/push-notification.key', {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((r) => r.json())
    return key
  }

  /**
   * @param {PushSubscription} subscription
   * @returns {Promise<void>}
   */
  async function saveSubscription(subscription) {
    const data = subscription.toJSON()
    // eslint-disable-next-line no-underscore-dangle
    Drequest.api('push-subscription.register')
      .data({
        push_subscription: {
          'subscription_type': 'user',
          'subscription_id': Drequest.getUser().id,
          'endpoint': data.endpoint,
          'public_key': data.keys.p256dh,
          'auth_token': data.keys.auth,
        }
      })
      .raw(r => console.log(r))

  }

  register('firebase-messaging-sw.js')

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready: async function (registration) {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      )
      if (Drequest.getUser().id) {
        /* const permission = await Notification.requestPermission()
        console.log(permission)
        if (permission === 'granted') {
          let subscription = await registration.pushManager.getSubscription()
          console.log(subscription)
          // L'utilisateur n'est pas déjà abonné, on l'abonne au notification push
          if (!subscription) {
            subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: await getPublicKey(),
            })
          }

          await saveSubscription(subscription)
        } */

      }
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      /* registration.showNotification('tests', {
        body:'test notif'
      }) */
      console.log('register event listener')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })

}
