<template>
  <v-card >
    <v-card-title>
      Reactions {{ kolall.nb_element }}
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>

    <template v-if="kolall.listEntity" >
      <v-list dense>
        <template v-for="(item, index) in kolall.listEntity">
          <v-divider
            :key="index"
          ></v-divider>

          <v-list-item dense
                       :key="item.id"
          >
            <v-list-item-avatar>
              <v-img
                v-if="item.user.avatar"
                contain
                :src="require('@/assets/images/avatars/'+item.user.avatar)"
              ></v-img>
              <v-img
                v-else
                contain
                :src="require('@/assets/images/avatars/logo.png')"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.user.username }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-btn v-if="kolall.remain >= 1" @click="showMore()">
        Voir plus
      </v-btn>
    </template>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiCommentAlertOutline,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import CommentFormComponent from '@/components/CommentFormComponent'
import CommentResponseListComponent from '@/components/CommentResponseListComponent'
import KolaToggleComponent from '@/components/KolaToggleComponent'
import CommentComponent from "@/components/CommentComponent.vue";

export default {
  components: {
    CommentComponent,
    KolaToggleComponent,
    CommentResponseListComponent,
    CommentFormComponent
  },
  props: {
    post_id: Number,
    user: {
      type: Object,
      default: () => {

      },
    },
  },
  setup(props) {
    const kolall = ref({ listEntity: [] })
    const init = (next) => {
      Drequest.api(`lazyloading.kola?dfilters=on&post.id:eq=${props.post_id}`)
        .param({
          next: next,
          'user_id:neq': props.user.id,
          per_page: 30,
        })
        .get(response => {
          console.log(response)

          if (next >= 2) {
            kolall.value.listEntity = [...kolall.value.listEntity, ...response.listEntity]
            kolall.value.next = response.next
            kolall.value.remain = response.remain
          } else
            kolall.value = response
        })
    }

    init(1)

    const showMore = ()=>{
      init(kolall.value.next)
    }

    return {
      kolall,
      showMore,

      icons: {
        mdiDotsHorizontal,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiDotsVertical,
        mdiCommentAlertOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

/*.comment-form {
  position: absolute;
  bottom: 0;
  background: white;
}*/
</style>
