<template>
  <v-card >
    <v-card-title >
      Partager sur
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" icon>
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      https://reader.3ag-edition.com{{linkshare}}
    </v-card-text>
    <v-card-text class="text-center">

      <ShareNetwork class="ma-3 text-decoration-none"
                    network="WhatsApp"
                    :url="baseurl+linkshare"
                    title="3ag reader"
                    hashtags="3ag reader"
      >
        <v-btn block color="secondary"><v-icon>{{ icons.mdiWhatsapp }}</v-icon> WhatsApp</v-btn>
      </ShareNetwork>
      <ShareNetwork class="ma-3 text-decoration-none"
                    network="facebook"
                    :url="baseurl+linkshare"
                    title="3ag reader"
                    hashtags="3ag reader"
      >
        <v-btn block color="secondary"><v-icon>{{ icons.mdiFacebook }}</v-icon> Facebook</v-btn>
      </ShareNetwork>
      <ShareNetwork class="ma-3 text-decoration-none"
                    network="Twitter"
                    :url="baseurl+linkshare"
                    title="3ag reader"
                    hashtags="3ag reader"
      >
        <v-btn block color="secondary"><v-icon>{{ icons.mdiTwitter }}</v-icon> Twitter</v-btn>
      </ShareNetwork>
      <ShareNetwork class="ma-3 text-decoration-none"
                    network="Telegram"
                    :url="baseurl+linkshare"
                    title="3ag reader"
                    hashtags="3ag reader"
      >
        <v-btn block color="secondary"><v-icon>{{ icons.mdiTelegram }}</v-icon> Telegram</v-btn>
      </ShareNetwork>
      <ShareNetwork class="ma-3 text-decoration-none"
                    network="Pinterest"
                    :url="baseurl+linkshare"
                    title="3ag reader"
                    hashtags="3ag reader"
      >
        <v-btn block color="secondary"><v-icon>{{ icons.mdiPinterest }}</v-icon> Pinterest</v-btn>
      </ShareNetwork>
      <ShareNetwork class="ma-3 text-decoration-none"
                    network="Reddit"
                    :url="baseurl+linkshare"
                    title="3ag reader"
                    hashtags="3ag reader"
      >
        <v-btn block color="secondary"><v-icon>{{ icons.mdiReddit }}</v-icon> Reddit</v-btn>
      </ShareNetwork>
      <ShareNetwork class="ma-3 text-decoration-none"
                    network="Messenger"
                    :url="baseurl+linkshare"
                    title="3ag reader"
                    hashtags="3ag reader"
      >
        <v-btn block color="secondary"><v-icon>{{ icons.mdiFacebookMessenger }}</v-icon> Messenger</v-btn>
      </ShareNetwork>
      <!--          <ShareFacebook class="ma-3" size="l" :url="linkshare"></ShareFacebook>
                <ShareTwitter class="ma-3" size="l" :rl="linkshare"/>-->

      <v-btn block
             elevation="0" link class="" @click="copytoclipboard"
      >
        <v-icon>{{ icons.mdiContentCopy }}</v-icon> Copier le lien de la publication
      </v-btn>

    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiWhatsapp,
  mdiFacebook,
  mdiPinterest,
  mdiReddit,
  mdiFacebookMessenger,
  mdiTwitter,
  mdiTelegram,
  mdiDotsVertical,
  mdiCommentOutline,
  mdiShareVariant,
  mdiHeartOutline,
  mdiHeart,
  mdiMinus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiShareVariantOutline,
  mdiClose,
  mdiCodeTags,
  mdiContentCopy,
  mdiCircle,
  mdiCircleOutline,
  mdiCollapseAllOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'

import CommentComponent from '@/components/CommentComponent'
import ToggleFavoriteComponent from '@/components/ToggleFavoriteComponent'

export default {
  name: 'ShareComponent',
  props:{
    "linkshare": String
  },
  setup(props){

    const baseurl = 'https://reader.3ag-edition.com'

    const copytoclipboard = () => {
      navigator.clipboard.writeText(baseurl+props.linkshare)
      alert('Le lien de la publication a bien ete copier dans le presse papier!')
    }
    return {
      copytoclipboard,
      baseurl,
      icons: {
        mdiClose,
        mdiDotsVertical,
        mdiCommentOutline,
        mdiShareVariant,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiMinus,
        mdiShareVariantOutline,
        mdiCodeTags,
        mdiContentCopy,
        mdiCircle,
        mdiCircleOutline,
        mdiCollapseAllOutline,
        mdiWhatsapp,
        mdiFacebook,
        mdiPinterest,
        mdiReddit,
        mdiFacebookMessenger,
        mdiTwitter,
        mdiTelegram,
      },
    }
  }
}
</script>

<style scoped>

</style>
