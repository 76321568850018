<template>
  <div class="">
      <h3 class="pa-1">
        Dernieres sorties de chapitres
      </h3>
    <v-card elevation="0" v-if="ll.listEntity.length == 0"  v-for="n in 6"
            :color="`grey darken-2 `"
            :key="n"
    >
      <v-skeleton-loader
        class="mx-auto"
        :height="72"
        type="list-item-avatar-two-line"
      ></v-skeleton-loader>
    </v-card>
    <v-card>
    <v-card-text class="pa-lg-6 pa-sm-3">

      <v-list    class="pa-0">
        <template v-for="(item, index) in ll.listEntity">
          <!--          <v-divider
                      :key="index"
                    ></v-divider>-->
          <chapter-list-item-component :dense="false"
            :item="item" :owner="false"></chapter-list-item-component>
        </template>
      </v-list>
    </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { getVuetify } from '@/plugins/utils'
import Banner from '@/components/Banner'
import ChapterListItemComponent from "@/components/comicbook/ChapterListItemComponent.vue";

export default {
  components: {ChapterListItemComponent, Banner },
  setup() {
    const $vuetify = getVuetify()
    const items = ref([])
    const drawer = ref(false)
    const tab = ref('cb')
    const chapters = ref([])
    const ll = ref({listEntity:[]})
    const columns = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return 150
      }
      if ($vuetify.breakpoint.xs) {
        return 108
      }

      return 210
    })
    const init = () => {
      Drequest.api('lazyloading.chapter?dfilters=on&status:eq=1&dsort=id desc')
        .get(response => {
          console.log(response)
          ll.value = response
        })
    }
    init()
    const detail = cb => {
      drawer.value = true
      comicbook.value = cb
    }

    return {
      detail,

      tab,
      items,
      ll,
      columns,
      drawer,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
