<script  >

import PWAPrompt from "@/layouts/components/PWAPrompt.vue";
import VerticalNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import AppBarUserMenu from "@/layouts/components/AppBarUserMenu.vue";
import BottomNavigation from "@/layouts/components/BottomNavigation.vue";
import FlatScreen from "@/layouts/components/FlatScreen.vue";
import {
  mdiAccessPoint,
  mdiAccountGroupOutline,
  mdiAccountOutline, mdiCogOutline, mdiCreation, mdiEyeOffOutline, mdiEyeOutline,
  mdiFacebook, mdiGamepadCircleOutline,
  mdiGithub,
  mdiGoogle, mdiGrid,
  mdiInstagram,
  mdiTwitter, mdiYoutube
} from "@mdi/js";

export default {
  components: {
    PWAPrompt, VerticalNavMenu, AppBarUserMenu, BottomNavigation, FlatScreen},

  data: () => ({
    dialog: false,
    isDrawerOpen: false,
    bnav: 'home',
    links: [ //'Accueil',
      {name: 'A propos de 3ag edition', to: "https://3ag-edition.com/about-us"},
      {name: 'Se faire editer', to: "https://3ag-edition.com/fr/how-to-publish"},
      {name: 'Boutique en ligne', to: "https://buyamsellam24.com/fr/shops"},
      //'Se faire editer', 'Nos Points de vente', 'Boutique en ligne', 'Nous contacter'
    ],
    icons: {
      mdiAccountOutline,
      mdiTwitter,
      mdiGithub,
      mdiGoogle,
      mdiFacebook,
      mdiInstagram,
      mdiYoutube,
      mdiEyeOutline,
      mdiCogOutline,
      mdiCreation,
      mdiGrid,
      mdiAccountGroupOutline,
      mdiAccessPoint,
      mdiGamepadCircleOutline,
      mdiEyeOffOutline,
    },
    height: window.innerHeight,
  }),

}
</script>

<template>

  <v-footer color="transparent"
            class=" text-center d-flex flex-column pb-12"
  >
    <div>
      <v-btn href="https://www.facebook.com/3AgManga"
             class="mx-4" target="_blank"
             icon
             variant="text"
      >
        <v-icon>{{ icons.mdiFacebook }}</v-icon>
      </v-btn>
      <v-btn href="https://www.instagram.com/editorial3ag/"
             class="mx-4" target="_blank"
             icon
             variant="text"
      >
        <v-icon>{{ icons.mdiInstagram }}</v-icon>
      </v-btn>
      <v-btn href="https://www.youtube.com/@edition3ag125/videos"
             class="mx-4" target="_blank"
             icon
             variant="text"
      >
        <v-icon>{{ icons.mdiYoutube }}</v-icon>
      </v-btn>
      <!--        <v-btn
                class="mx-4"
                icon
                variant="text"
              ><v-icon>{{ icons.mdiGoogle }}</v-icon>

              </v-btn>-->
    </div>

    <div class="pt-0">
      Plateforme de lecture en ligne des BDs editer par 3ag edition. Espace d'expression de la
      communaute ainsi que
      la creation de contenu pour les auteurs libres.
    </div>

    <v-divider></v-divider>
    Liens utils
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links" rounded
        :key="link.to" target="_blank"
        variant="text"
        class="ma-2"
        :href="link.to"
      >
        {{ link.name }}
      </v-btn>
    </v-row>
    <v-divider></v-divider>

    <div class="mt-6 mb-6">
      {{ new Date().getFullYear() }} — <strong>3ag Edition</strong> <span
      class="">
            <a
              href="https://business.spacekola.com"
              target="_blank"
              class="text--secondary text-decoration-none"
            >Developed by Spacekola</a>
          </span>
    </div>
  </v-footer>
</template>

<style scoped lang="scss">

</style>
