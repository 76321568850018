<template>
<span>
  <v-btn
    :loading="loader.like"
    icon x-small
    @click="toggleKola"
  >
          <v-icon :color="liked ?'primary': 'secondary'"
          >{{ liked ? icons.mdiHeart : icons.mdiHeartOutline }}</v-icon>
        </v-btn>
        <span v-if="nbkola>0">{{ nbkola }}</span>
</span>
</template>

<script>
import { Drequest } from '@/plugins/Drequest'
import { computed, ref } from '@vue/composition-api'
import {
  mdiComment,
  mdiDotsVertical, mdiEyeOffOutline,
  mdiEyeOutline,
  mdiHeart,
  mdiHeartOutline,
  mdiShareVariant
} from '@mdi/js'

export default {
  name: 'KolaToggleComponent',
  props: {
    'subject': String,
    'identify': Number| String,
    'status': Number | Boolean,
    'counter': Number | String,
    user_id: {
      type: Number | String,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    let liked = computed(() => props.status)
    let nbkola = computed(() => props.counter)

    const loader = ref({
      loading: true,
      like: false,
    })
    const toggleKola = () => {
      if (props.user_id) {
        loader.value.like = true
        Drequest.toggleKola(props.subject, props.identify, liked.value)
          .post(response => {
            console.log(response)
            loader.value.like = false
            emit('liked', !liked.value, response.nbkola)
          })
      } else {
        alert('Vous devez etre connecte pour liker')
      }
    }
    return {
      loader,
      nbkola,
      liked,
      toggleKola,
      icons: {
        mdiDotsVertical,
        mdiComment,
        mdiShareVariant,
        mdiHeart,
        mdiHeartOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  }
}
</script>

<style scoped>

</style>
