<template>

  <v-card  >
    <v-card-title class="pa-2 white--text orange darken-4 sticky-top-0">
      Images

      <v-spacer></v-spacer>

      <v-btn
        @click="$emit('close')"
        color="primary"
        small fab
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      v-html="post.contenthtml">
    </v-card-text>
    <v-img
      v-for="(item, $index) in postimages"
      :key="'wt-'+item.id"
      eager
      class="ma-auto"
      max-width="800"
      :src="item.src"
      :lazy-src="require('@/assets/images/holder.jpg')"
    ></v-img>
  </v-card>
</template>

<script>
import {
  mdiChevronLeft,
  mdiPlus,
  mdiDotsVertical,
  mdiBookmark,
  mdiShareVariant,
  mdiHeart,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiClose,
} from '@mdi/js'
export default {
  name: 'ImageViewer',
  props: {
    'post': Object,
    'postimages': Array,
  },
  setup(props) {
    console.log(props.postimages)

    return {

      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiBookmark,
        mdiShareVariant,
        mdiHeart,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiClose,
      },
    }
  }
}
</script>

<style scoped>

</style>
