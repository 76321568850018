<script >

import FollowToggleComponent from "@/components/FollowToggleComponent.vue";

export default {
  props:{
    participant:Object,
    user:Object,
  }
}

</script>

<template>

  <v-card
    class="d-inline-block mr-lg-2 ml-lg-2 mr-sm-1 ml-sm-1 mt-3 text-center"
    :to="'/contest/'+participant.id+'/enroll'"
  >
    <v-avatar
      size="100"
      class="ma-1 av-active"
    >
      <v-img
        v-if="participant.cover"
        alt="Avatar"
        contain
        :src="participant.cover"
        :lazy-src="require(`@/assets/images/avatars/logo.png`)"
      />
      <v-img
        v-else
        alt="Avatar"
        contain
        :src="require(`@/assets/images/avatars/logo.png`)"
      />
    </v-avatar>
    <v-card-title class="title-inline ma-1 pa-0 text-center">
      <b>{{ participant.title }}</b>
    </v-card-title>
    <p class="title-inline  pa-0 text-center">
       {{ participant.user.username }}
    </p>
  </v-card>
</template>

<style scoped lang="scss">

</style>
